<template>
	<div class="modal-inner">
		<h2>Settings</h2>
		<p>These settings apply to the group <strong>{{ course.name }}</strong>.</p>
		<ul class="modal-content">
			<li v-if="course._own !== false">
				<label>Name</label>
				<input v-model="courseName" type="text" />
			</li>

			<li v-for="setting in settings" :key="setting.key">
				<label v-if="!setting.hideLabel">{{ setting.label }}</label>
				<v-select v-if="setting.type === 'select'" v-model="course.meta[setting.key]" :options="filterOptions(setting.options)" :reduce="setting.reduce" label="label" :clearable="false" :searchable="false" :multiple="setting.multiple" />
				<input v-else-if="setting.type === 'text'" v-model="course.meta[setting.key]" type="text" :placeholder="setting.placeholder" @blur="setting.onblur(course)" />
				<input v-else-if="setting.type === 'number'" v-model="course.meta[setting.key]" type="number" @blur="setting.onblur(course)" />
				<BoolInput v-else-if="setting.type === 'checkbox'" :checked="course.meta[setting.key]" :label="setting.label" @input="checked => course.meta[setting.key] = checked" />
				<div v-else-if="setting.type === 'range'" class="range">
					<span class="min">{{ setting.min }}</span>
					<input v-model="course.meta[setting.key]" type="range" :min="setting.min" :max="setting.max" />
					<span class="max">{{ setting.max }}</span>
					<span class="current">{{ course.meta[setting.key] }}</span>
				</div>
				<BaseButton v-if="setting.type === 'button'" type="primary" @click="() => doButtonAction(setting.action)">
					{{ setting.label }}
				</BaseButton>
				<small v-if="course.meta[setting.key] === 'inherit' && currentSettings[setting.key]">Value <strong>{{ currentSettings[setting.key].value }}</strong> inherited from <a @click="openModal(currentSettings[setting.key].course)">{{ currentSettings[setting.key].course.name }}</a></small>
			</li>

			<li v-if="isRoot">
				<BoolInput :checked="course.meta.isPublic" label="Enable public access link" @input="checked => $set(course.meta, 'isPublic', checked)" />
				<div v-if="course.meta.isPublic" class="public-link">
					<input type="text" readonly :value="publicLink" />
					<span role="button" @click="copyLink">{{ copied ? 'Copied!' : 'Copy' }}</span>
				</div>
			</li>
		</ul>
		<div class="modal-footer">
			<BaseButton type="secondary" @click="reset">
				Reset
			</BaseButton>
			<BaseButton type="delete" @click="deleteCourse">
				Delete
			</BaseButton>
			<BaseButton type="primary" @click="save">
				Save
			</BaseButton>
		</div>
	</div>
</template>

<script>
	/* eslint-disable no-underscore-dangle */
	import BaseButton from './BaseButton';
	import Bus from '../inc/bus';
	import {getSettings, deleteGroup, getGroup, getNext} from '../inc/courseUtils';
	import {groupSettings, getDefaultSettings} from '../inc/groupSettings';
	import {addDefaults} from '../inc/utils';
	import BoolInput from './BoolInput';
	import Store from '../inc/store';
	import Backend from '../inc/backend';
	import CefrModal from './CefrModal.vue';

	export default {
		name: 'SettingsModalCourse',
		components: {
			BaseButton,
			BoolInput
		},
		props: {
			course: Object
		},
		data() {
			return {
				originalData: '',
				copied: false
			};
		},
		computed: {
			currentSettings() {
				return getSettings(this.course);
			},
			settings() {
				return groupSettings.filter(gs => {
					if(this.isRoot && gs.childOnly) {
						return false;
					}
					if(!this.isRoot && gs.rootOnly) {
						return false;
					}
					if(this.course._own === false && !gs.allowOverride) {
						return false;
					}

					return true;
				});
			},
			courseName: {
				get() {
					return this.course.name;
				},
				set(newName) {
					if(newName.length > 1 && newName.length < 256) {
						this.course.name = newName;
					}
				}
			},
			isRoot() {
				return Store.course.id === this.course.id;
			},
			publicLink() {
				return location.protocol + '//' + location.host + '/public-course/' + this.course.id;
			}
		},
		created() {
			this.originalData = JSON.stringify(this.course);
			addDefaults(this.course.meta, getDefaultSettings(this.isRoot), true);
		},
		methods: {
			save() {
				Bus.emit('saveCourseSettings', this.course);

				this.$modal.hideAll();
			},
			reset() {
				Object.assign(this.course, JSON.parse(this.originalData));
				this.$modal.hideAll();
			},
			openModal(course) {
				Bus.emit('openCourseSettings', course.id);
			},
			filterOptions(options) {
				if(this.isRoot) {
					return options.filter(option => option.code !== 'inherit');
				}

				return options;
			},
			copyLink() {
				navigator.clipboard.writeText(this.publicLink);
				this.copied = true;
			},
			deleteCourse() {
				this.$modal.hideAll();
				const {parentId, id} = getGroup(this.course.id);

				this.$modal.show('dialog', {
					title: `Delete ${parentId ? 'course' : 'group'}?`,
					text: `This will permanently delete this ${parentId ? 'course' : 'group'}. Do you want to proceed?`,
					buttons: [
						{
							title: 'Cancel',
							handler: () => {
								this.$modal.hide('dialog');
							}
						},
						{
							title: 'Delete',
							class: 'vue-dialog-button danger',
							handler: () => {
								let promise = null;

								if(parentId) {
									let groupId = parentId === Store.course.id ? getNext(id, -1, true) : parentId;

									if(!groupId) {
										groupId = getNext(Store.course.id, 1, true);
									}

									promise = deleteGroup(parentId, id)
										.then(() => this.$router.push({name: 'CourseGroup', params: {courseId: Store.course.id, groupId}}));
								}
								else {
									promise = Backend.delete(`course/${id}`)
										.then(() => this.$router.push({name: 'MyCourses'}));
								}

								promise
									.then(() => Bus.emit('success', `${parentId ? 'Course' : 'Group'} deleted`))
									.catch(err => Bus.emit('error', err.message));

								this.$modal.hide('dialog');
							}
						}
					]
				});
			},
			doButtonAction(action) {
				if(action === 'editCEFR') {
					this.$modal.show(CefrModal, {group: this.course}, {classes: 'cefr-modal', height: 'auto', name: 'CefrModal'});
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	h2 {
		margin-bottom: 0.5em;
	}

	p {
		margin-bottom: $default_padding;
	}

	ul {
		padding: 0;
		list-style-type: none;
	}

	li::v-deep {
		margin-bottom: $default_padding;

		.v-select {
			margin-bottom: 0;
		}
	}

	small {
		font-style: italic;
	}

	.public-link {
		display: flex;
		flex-flow: row nowrap;
		margin-bottom: $default_padding / 2;

		input {
			margin-bottom: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right-width: 0;
		}

		span {
			border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
			border: $border;
			padding: 10px;
			background-color: $color__gray;
			cursor: pointer;
			transition: background-color 0.1s ease;

			&:hover {
				background-color: darken($color: $color__gray, $amount: 5%)
			}
		}
	}
</style>