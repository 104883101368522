<template>
	<div id="app" @click="clickAnywhere">
		<transition name="page">
			<component :is="layout" v-if="loaded" :class="[layout, 'layout']">
				<router-view />
			</component>
		</transition>

		<!-- <bounce-loader class="main-spinner" :loading="!loaded" :color="''" /> -->

		<modal name="auth-modal" :focus-trap="true" :adaptive="true" :click-to-close="false">
			<form method="post" @submit.prevent="login">
				<h2>Your session has ended</h2>
				<div class="modal-content">
					<p>Please enter your credentials below to continue.</p>
					<br />

					<input v-model="email" type="email" placeholder="Email address" /><br />
					<input v-model="password" type="password" placeholder="Password" /><br />
				</div>
				<div class="modal-footer">
					<BaseButton type="link" :href="{ name: 'RecoverPassword' }" class="left">
						Forgot password?
					</BaseButton>
					<BaseButton type="submit">
						Login
					</BaseButton>
				</div>
			</form>
		</modal>

		<v-dialog />
	</div>
</template>

<script>
	import Store from './inc/store';
	import Bus from './inc/bus';
	import Backend from './inc/backend';
	import BaseButton from './components/BaseButton';

	// Layouts
	import DefaultLayout from './layouts/Default.vue';
	import ReviewLayout from './layouts/Review.vue';
	import CourseLayout from './layouts/Course.vue';
	import LoginLayout from './layouts/Login.vue';

	export default {
		components: {
			'default-layout': DefaultLayout,
			'review-layout': ReviewLayout,
			'course-layout': CourseLayout,
			'login-layout': LoginLayout,
			'admin-layout': () => import('./layouts/Admin.vue'),
			BaseButton
		},
		data() {
			return {
				email: '',
				password: ''
			};
		},
		computed: {
			layout() {
				return (this.$route.meta.layout || 'default') + '-layout';
			},
			loaded() {
				return Store.loaded;
			}
		},
		watch: {
			$route: {
				immediate: true,
				handler(to) {
					document.title = (to.meta && to.meta.title) || 'Elemes';
				}
			}
		},
		created() {
			Bus.on('error', e => {
				const {type, code} = e;

				if(type === 'axios' && code === 401 && Store.loggedIn) {
					this.$modal.show('auth-modal');
				}
			});
		},
		methods: {
			clickAnywhere(e) {
				Bus.emit('clickAnywhere', e);
			},
			login() {
				Backend.post('auth', {
					email: this.email,
					password: this.password
				}).then(() => {
					this.email = '';
					this.password = '';
					location.reload();
				}).catch(err => {
					Bus.emit('error', err);
				});
			}
		}
	};
</script>

<style lang="scss">
	@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');
	@import './sass';
</style>