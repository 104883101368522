<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" stroke-miterlimit="10" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><circle fill="none" cx="4" cy="4" r="2" :stroke="fill" /> <circle fill="none" cx="4" cy="12" r="2" :stroke="fill" /> <circle fill="none" cx="4" cy="20" r="2" :stroke="fill" /> <line fill="none" x1="10" y1="4" x2="22" y2="4" /> <line fill="none" x1="10" y1="12" x2="22" y2="12" /> <line fill="none" x1="10" y1="20" x2="22" y2="20" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 2
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>