<template>
	<div class="login view">
		<NoticeArea />

		<BaseCard v-if="!$route.meta.recoverPassword" title="Login">
			<form method="post" @submit.prevent="login">
				<input ref="email" v-model="email" type="email" placeholder="Email address" /><br />
				<input v-model="password" type="password" placeholder="Password" /><br />
				<div class="buttons">
					<BaseButton type="link" :href="{ name: 'RecoverPassword' }" class="left">
						Forgot password?
					</BaseButton>
					<BaseButton v-if="enableRegistration" type="link" :href="{ name: 'Register' }" class="left">
						Register
					</BaseButton>
					<BaseButton type="submit">
						Login
					</BaseButton>
				</div>
			</form>
		</BaseCard>

		<BaseCard v-else title="Recover password">
			<form method="post" @submit.prevent="recoverPassword">
				<input ref="email" v-model="email" type="email" placeholder="Email address" autofocus="autofocus" /><br />
				<div class="buttons">
					<BaseButton type="link" :href="{ name: 'Login' }" class="left">
						Back to login
					</BaseButton>
					<BaseButton type="submit">
						Recover
					</BaseButton>
				</div>
			</form>
		</BaseCard>

		<!-- <small class="terms">By signing in, you accept our <a href="#" target="_blank" rel="noopener noreferrer">privacy policy</a>.</small> -->
	</div>
</template>

<script>
	import Backend from '../inc/backend';
	import Store from '../inc/store';
	import Router from '../inc/router';
	import BaseCard from '../components/BaseCard.vue';
	import BaseButton from '../components/BaseButton.vue';
	import Bus from '../inc/bus';

	export default {
		components: {
			BaseCard,
			BaseButton
		},
		data() {
			return {
				email: '',
				password: ''
			};
		},
		computed: {
			enableRegistration() {
				return Store.tenant && Store.tenant.enableRegistration;
			}
		},
		watch: {
			$route() {
				this.focusOnEmail();
			}
		},
		mounted() {
			this.focusOnEmail();
		},
		methods: {
			focusOnEmail() {
				this.$refs.email.focus();
			},

			login() {
				Backend.post('auth', {
					email: this.email,
					password: this.password
				}).then(() => {
					Store.loaded = false;
					Router.push({path: this.$route.query.redirect || '/'});
				}).catch(err => {
					Bus.emit('error', err);
				});
			},

			recoverPassword() {
				Backend.post('auth/recover-password', {
					email: this.email
				}).then(response => {
					if(response.data.token) {
						Router.push({name: 'RecoverPasswordToken', params: {token: response.data.token}});
					}
					else {
						Bus.emit('success', 'Thank you - we will send an email shortly');
					}
				}).catch(err => {
					Bus.emit('error', err);
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.view {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		height: 100%;

		.card {
			max-width: 400px;
			width: 100%;
		}

		form {
			overflow: hidden;
		}

		.button {
			float: right;
			clear: both;
		}

		.terms {
			a {
				color: $color__dark;
				text-decoration: none;
				font-weight: $font__semibold;
			}
		}
	}
</style>