<template>
	<div class="modal-inner">
		<IconCross :width="16" :height="16" class="close" @click.native="$modal.hideAll()" />
		<h2>Detach cloned block</h2>
		<div class="modal-content">
			<p>Detaching a cloned block means it will no longer be updated along with the other clones and all user progress will be lost for this course group. Are you sure you want to detach this block?</p>
		</div>
		<div class="modal-footer">
			<BaseButton type="secondary" @click="$modal.hideAll()">
				Cancel
			</BaseButton>
			<BaseButton type="primary" @click="detach">
				Detach
			</BaseButton>
		</div>
	</div>
</template>

<script>
	import IconCross from './icons/IconCross';
	import BaseButton from './BaseButton';
	import Backend from '../inc/backend';
	import Bus from '../inc/bus';
	import {copyBlock, fetchGroupBlocks} from '../inc/courseUtils';
	import Store from '../inc/store';

	export default {
		name: 'DetachBlockModal',
		components: {
			IconCross,
			BaseButton
		},
		props: {
			block: Object
		},
		computed: {
			courseId() {
				return Store.course.id;
			},
			groupId() {
				return Store.courseGroup.id;
			}
		},
		methods: {
			detach() {
				return copyBlock(this.block, this.courseId, this.groupId)
					.then(() => Backend.delete(`courses/${this.courseId}/groups/${this.groupId}/blocks/${this.block.id}`))
					.then(() => fetchGroupBlocks())
					.then(() => {
						Bus.emit('Block has been detached.');
						this.$modal.hideAll();
					})
					.catch(err => Bus.emit('error', err.message));
			}
		}
	};
</script>

<style lang="scss" scoped>
	.modal-content {
		margin-bottom: $default_padding;
	}
</style>