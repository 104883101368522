<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" :width="width" :height="height"><g :stroke-width="strokewidth" :fill="fill" :stroke="fill"><path fill="none" stroke-linecap="square" stroke-miterlimit="10" d="M28 9.9c4.2 2.4 7 6.9 7 12.1 0 7.7-6.3 14-14 14s-14-6.3-14-14c0-5.2 2.8-9.7 7-12.1" stroke-linejoin="miter" :stroke="fill" /><path fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-linejoin="miter" d="M21 6v9" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>