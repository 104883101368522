import AdminCourses from './admin-courses';
import AdminClasses from './admin-classes';
import AdminSubmissions from './admin-submissions';
import AdminUsers from './admin-users';
import AdminSettings from './admin-settings';
import AdminAffiliates from './admin-affiliates';
import AdminIntegrations from './admin-integrations';

export default [
	{
		path: '/admin',
		name: 'Admin',
		redirect: {name: 'AdminCourses'}
	},

	...AdminCourses,
	...AdminClasses,
	...AdminSubmissions,
	...AdminUsers,
	...AdminSettings,
	...AdminAffiliates,
	...AdminIntegrations
];