<template>
	<button v-if="course" :class="{'edit-mode': editMode}" @click="toggleEditMode">
		<span class="preview">
			<IconEye :width="28" :height="28" />
		</span>
		<span class="edit">
			<IconPen :width="28" :height="28" />
		</span>
	</button>
</template>

<script>
	import Store from '../inc/store';
	import IconEye from './icons/IconEye';
	import IconPen from './icons/IconPen';
	import {beforeUnloadMessage} from '../inc/router.js';

	export default {
		components: {
			IconEye,
			IconPen
		},
		computed: {
			course() {
				return Store.course;
			},
			editMode() {
				return Store.editMode;
			}
		},
		methods: {
			toggleEditMode() {
				if(Store.editMode && Store.hasUnsavedChanges) {
					// eslint-disable-next-line no-alert
					if(confirm(beforeUnloadMessage)) {
						Store.hasUnsavedChanges = false;
						Store.editMode = false;
					}
				}
				else {
					Store.editMode = !Store.editMode;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	button {
		position: fixed;
		top: $default_padding * 2;
		right: $default_padding * 6;
		text-align: right;
		user-select: none;
		display: flex;
		align-items: center;
		width: 100px;
		height: 40px;
		border-radius: 20px;
		background-image: linear-gradient(90deg, $color__link 50%, $color__white 50%);
		background-position: 0 0;
		transition: background-position 200ms;
		z-index: 10;
		outline: none;
		box-shadow: $box_shadow;

		&:hover {
			background-image: linear-gradient(90deg, $color__link 50%, $color__gray 50%);
		}
	}

	span {
		flex: 1;
		text-align: center;
		transition: color 200ms;
	}

	.preview {
		color: $color__white;
	}

	.edit-mode {
		background-position: 50px 0;

		.preview {
			color: inherit;
		}

		.edit {
			color: $color__white;
		}
	}
</style>