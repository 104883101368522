<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><polyline fill="none" stroke-miterlimit="10" points="19,7 26,16 21.958,20.311 " stroke-linecap="butt" /> <polyline fill="none" stroke-miterlimit="10" points="13,7 10,1 22,1 19,7 " stroke-linecap="butt" :stroke="fill" /> <polygon fill="none" stroke-miterlimit="10" points="13,7 9,25 16,31 23,25 19,7 " :stroke="fill" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>