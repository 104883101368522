<template>
	<ol class="course-tree">
		<CourseTreeNode v-for="node in value" :key="node.id" :mode="mode" :node="node" />
	</ol>
</template>

<script>
	import CourseTreeNode from './CourseTreeNode';

	export default {
		name: 'CourseTree',
		components: {
			CourseTreeNode
		},
		props: {
			value: {
				type: Array,
				default: () => []
			},
			mode: {
				type: String,
				default: 'default'
			}
		}
	};
</script>

<style lang="scss" scoped>
	ol {
		padding-left: 0;
	}
</style>