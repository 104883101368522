<template>
	<div class="modal-inner">
		<IconCross :width="16" :height="16" class="close" @click.native="$modal.hideAll()" />
		<h2>Clone block</h2>
		<div class="modal-content">
			<label for="clone-course">Course</label>
			<v-select v-model="courseId" :options="courses" :reduce="value => value.code" label="label" input-id="clone-course" :clearable="false" :searchable="true" placeholder="Select target course" />
			<label for="clone-group">Group</label>
			<v-select v-model="groupId" :options="groups" :reduce="value => value.code" label="label" input-id="clone-group" :clearable="false" :searchable="true" placeholder="Select target group" :disabled="!groups.length" />
			<label>Method</label>
			<BoolInput name="clone-method" :radio="true" label="Copy content" :checked="true" @input="method = 'copy'" />
			<BoolInput name="clone-method" :radio="true" label="Clone block" @input="method = 'clone'" />
		</div>
		<div class="modal-footer">
			<BaseButton type="primary" @click="clone">
				Clone block
			</BaseButton>
		</div>
	</div>
</template>

<script>
	import IconCross from './icons/IconCross';
	import BaseButton from './BaseButton';
	import Backend from '../inc/backend';
	import Bus from '../inc/bus';
	import Store from '../inc/store';
	import BoolInput from './BoolInput';
	import {cloneBlock, copyBlock, fetchGroupBlocks} from '../inc/courseUtils';

	export default {
		name: 'CloneBlockModal',
		components: {
			IconCross,
			BaseButton,
			BoolInput
		},
		props: {
			block: Object
		},
		data() {
			return {
				courseId: '',
				courses: [],
				groupId: '',
				groups: [],
				method: 'copy'
			};
		},
		watch: {
			courseId(id) {
				this.getGroups(id);
			}
		},
		created() {
			Backend.get('courses').then(res => {
				for(const course of res.data) {
					if(course.id === Store.course.id) {
						this.getGroups(course.id);
						this.courseId = course.id;
					}

					this.courses.push({
						label: course.name,
						code: course.id
					});
				}
			});
		},
		methods: {
			getGroups(id) {
				this.groupId = '';

				Backend.get(`courses/${id}`).then(res => {
					this.groups = [];

					const getChildren = (children, level) => {
						for(const child of children) {
							if(child.id === Store.courseGroup.id) {
								this.groupId = child.id;
							}

							this.groups.push({
								label: Array(level).join('-') + ' ' + child.name,
								code: child.id
							});

							if(child.children && child.children.length) {
								getChildren(child.children, level + 1);
							}
						}
					};

					getChildren(res.data.children, 1);
				});
			},
			clone() {
				let promise = null;

				if(this.method === 'copy') {
					promise = copyBlock(this.block, this.courseId, this.groupId);

					if(this.groupId === Store.courseGroup.id) {
						promise = promise.then(() => fetchGroupBlocks());
					}
				}
				else if(this.method === 'clone') {
					promise = cloneBlock(this.block, this.courseId, this.groupId);
				}

				return Promise.resolve(promise).then(() => {
					this.$modal.hideAll();
					Bus.emit('success', `Block has been ${this.method === 'copy' ? 'copied' : 'cloned'}`);
				}).catch(err => Bus.emit('error', err.message));
			}
		}
	};
</script>