<template>
	<div class="logo-wrapper">
		<object v-if="logoUrl" :data="logoUrl" :type="logoFileType"></object>
		<div v-else class="placeholder"></div>
	</div>
</template>

<script>
	import ObjectStore from '../inc/objectStore';
	import Store from '../inc/store';

	export default {
		data() {
			return {
				logoUrl: ''
			};
		},
		computed: {
			logoFileType() {
				if(Store.tenant.logotype) {
					return Store.tenant.logotype.type || '';
				}

				return '';
			}
		},
		created() {
			if(Store.tenant.logotype) {
				ObjectStore.getFileUrl(Store.tenant.logotype).then(url => {
					this.logoUrl = url;
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.logo-wrapper {
		padding: 0 $site__padding * 2;

		.logo {
			max-width: 222px;
		}

		object,
		.placeholder {
			pointer-events: none;
		}

		.placeholder {
			display: flex;
			width: 222px;
			height: 40px;
			outline: 2px dashed $border_color;
			align-items: center;
			justify-content: center;

			&::after {
				content: 'Logotype here';
				text-transform: uppercase;
			}
		}
	}
</style>