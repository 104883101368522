<template>
	<div>
		<AccountMenu />
		<EditButton v-if="canEdit" />
		<slot />
	</div>
</template>

<script>
	import AccountMenu from '../components/AccountMenu';
	import EditButton from '../components/EditButton';
	import {isAdmin} from '../inc/auth';
	import Store from '../inc/store';

	export default {
		components: {
			AccountMenu,
			EditButton
		},
		computed: {
			canEdit() {
				return isAdmin();
			},
			isPublic() {
				return Store.user.isPublic;
			}
		}
	};
</script>

<style lang="scss">
	.default-layout {
		.view > {
			main {
				margin-left: $sidebar__width;
				transition: margin-left 0.2s ease;
			}

			@include breakpoint-min('large') {
				.sidebar.active + main {
					margin-left: $sidebar__width_active;
				}
			}
		}
	}
</style>