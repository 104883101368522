/* eslint-disable array-element-newline, camelcase, require-jsdoc, no-param-reassign */
import striptags from 'striptags';
import slugify from 'slugify';

const nodes = {
	doc: null,
	text: null,
	paragraph: '<p>',
	hardbreak: '<br />',
	bulletlist: '<ul>',
	orderedlist: '<ol>',
	listitem: '<li>',
	heading: attrs => '<h' + parseInt(attrs.level, 10) + '>',
	table: '<table>',
	tablerow: '<tr>',
	tablecell: attrs => parseTableCell(attrs),
	options: attrs => [`<span class="options" data-id="${attrs['data-id']}">`, '</span>']
};

const marks = {
	bold: '<b>',
	underline: '<u>',
	italic: '<i>',
	link: attrs => [`<a href="${attrs.href}" rel="noopener noreferrer" target="${attrs.target || '_blank'}">`, '</a>']
};

function fixItem(item, itemType) {
	if(item) {
		if(itemType === 'string') {
			item = [item];
		}

		if(!item[1] && item[0].substr(-2) !== '/>') {
			item.push('</' + item[0].substr(1));
		}
	}

	return item;
}

function parseTableCell(attrs) {
	if(attrs.colwidth) {
		attrs.style = `width: ${attrs.colwidth[0]}px;`;
		Reflect.deleteProperty(attrs, 'colwidth');
	}

	let attributes = '';

	if(Object.keys(attrs).length) {
		// eslint-disable-next-line guard-for-in
		for(const key in attrs) {
			attributes += ` ${key}="${attrs[key]}"`;
		}
	}

	return [`<td${attributes}>`, '</td>'];
}

export function jsonToHtml(doc) {
	if(typeof doc === 'string') {
		if(doc[0] !== '{') {
			return doc;
		}

		doc = JSON.parse(doc);
	}

	if(typeof doc !== 'object' || !doc.type) {
		return '';
	}

	if(doc.type !== 'doc') {
		throw new Error('JSON root must be doc');
	}

	// eslint-disable-next-line complexity
	function build(el, textOnly = false) {
		const elementType = el.type.replace('_', '').toLowerCase();
		let itemType = (typeof nodes[elementType]);
		let start = '';
		let end = '';

		if(elementType === 'hardbreak') {
			return nodes[elementType];
		}

		if(itemType === 'undefined' || (elementType !== 'options' && !el.content && !el.text)) {
			return '';
		}

		let item = nodes[elementType];

		if(itemType === 'function') {
			item = item(el.attrs || {});
			itemType = (typeof item);
		}

		item = fixItem(item, itemType);

		if(item && !textOnly) {
			start += item[0];

			if(item[1]) {
				end = item[1] + end;
			}
		}

		if(el.marks) {
			for(const m of el.marks) {
				if(!marks[m.type]) {
					continue;
				}

				let markType = typeof marks[m.type];
				let mark = marks[m.type];

				if(markType === 'function') {
					mark = marks[m.type](m.attrs || {});
					markType = (typeof m);
				}

				mark = fixItem(mark, markType);

				start += mark[0];
				end = mark[1] + end;
			}
		}

		if(el.text) {
			start += striptags(el.text);
		}
		else if(el.content) {
			for(const sub of el.content) {
				start += build(sub, textOnly || (item && item[2]));
			}
		}

		return start + end;
	}

	return build(doc);
}

export function slug(str) {
	return slugify(str, {remove: /[^\w\s.-]+/gu});
}