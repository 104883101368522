import Store from './store';
import Bus from './bus';

/**
 * @param {string} collection The target collection
 * @param {string} key The role's key
 * @param {UserRole} level The role's level
 * @returns {boolean} Whether user has the role
 */
 export function hasRole(collection, key = 'any', level = 'any') {
	if(!Store.user || !Store.user.roles) {
		return false;
	}

	const checkRole = role => role.id === `${collection}/${key}` || (role.id.includes(collection) && key === 'any');
	const checkLevel = role => role.level === level || level === 'any';

	return Store.user.roles.some(r => checkRole(r) && checkLevel(r));
}

/**
 * @returns {boolean} Whether user has is admin on the current tenant
 */
export function isAdmin() {
	return hasRole('Tenants', Store.tenant.id, 'admin');
}

/**
 * @returns {boolean} Whether user has is admin on the current tenant
 */
export function isTeacher() {
	return hasRole('Tenants', Store.tenant.id, 'teacher') || hasRole('Courses', 'any', 'teacher');
}

/**
 * @param {string} collection The target collection or "admin" to check for global admin
 * @param {string} key The role key
 * @param {UserRole} level The role level
 * @returns {boolean} Whether user has the role
 */
export function roleGuard(collection, key = 'any', level = 'any') {
	return {
		beforeRouteEnter(to, from, next) {
			if(isAdmin() || hasRole(collection, key, level)) {
				next();
			}
			else {
				Bus.emit('error', 'You don\'t have permission to access this page.');
				next(false);
			}
		}
	};
}