<template>
	<draggable tag="aside" :list="blocks" :group="{ name: 'blocks', pull: 'clone', put: false }" :clone="cloneBlock" :sort="false" draggable="article" :class="{open}" @start="dragStart" @end="dragEnd">
		<article v-for="block in blocks" :key="block.type" class="legend" @click.stop>
			<span>{{ block.label }}</span>
			<IconMove width="1em" height="1em" />
		</article>
		<IconAdd :width="32" :height="32" @click.native.stop="toggleMenu" />
	</draggable>
</template>

<script>
	import draggable from 'vuedraggable';
	import IconAdd from './icons/IconAdd';
	import IconMove from './icons/IconMove';
	import Bus from '../inc/bus';
	import Store from '../inc/store';
	import blocks from '../inc/blockDefinitions';
	import {v4} from 'uuid';

	export default {
		components: {
			draggable,
			IconAdd,
			IconMove
		},

		data() {
			return {
				blocks,
				open: false
			};
		},

		mounted() {
			Bus.on('openedAccountMenu', () => this.closeLegend());
			Bus.on('clickAnywhere', () => this.closeLegend());
		},

		methods: {
			cloneBlock(block) {
				const {category, type, label, content, meta} = JSON.parse(JSON.stringify(block));

				return {
					id: v4(),
					category,
					type,
					label,
					content,
					meta
				};
			},
			toggleMenu() {
				this.open = !this.open;
			},
			closeLegend() {
				this.open = false;
			},
			dragStart() {
				Store.blockCloning = true;
			},
			dragEnd() {
				Store.blockCloning = false;
			}
		}
	};
</script>

<style lang="scss">
	.block-legend {
		position: fixed;
		bottom: $site__padding;
		right: $default_padding * 2;
		display: flex;
		flex-flow: column nowrap;
		align-items: stretch;
		z-index: 900;

		& > svg {
			display: block;
			border-radius: 50%;
			background: $color__white;
			padding: 10px;
			box-sizing: content-box;
			box-shadow: $box_shadow;
			cursor: pointer;
			transition: transform .1s ease-out;
			align-self: flex-end;
			background-color: $color__dark;
			color: $color__gray;
		}

		article {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			background-color: $color__white;
			box-shadow: $box_shadow;
			border-radius: $border_radius;
			margin-bottom: .3em;
			padding: .3em .6em;
			cursor: grab;
			font-size: 0;
			transition: font-size .1s ease-out;

			& > span {
				margin-right: 10px;
			}

			& > svg {
				margin-left: auto;
			}

			&:active {
				cursor: grabbing;
			}
		}

		&.open {
			article {
				font-size: 1.25rem;
			}

			& > svg {
				transform: rotate(45deg);
			}
		}
	}
</style>