import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from './store';
import Backend from './backend';

import MyCourses from '../views/MyCourses.vue';
import MyClasses from '../views/MyClasses.vue';
import Login from '../views/Login.vue';
import RegisterAccount from '../views/RegisterAccount.vue';
import RecoverPasswordToken from '../views/RecoverPasswordToken.vue';
import AddIssuer from '../views/settings/lti/AddIssuer.vue';
import SingleCourse from '../views/SingleCourse';
import ReviewCourse from '../views/ReviewCourse';
import AdminRoutes from './router/admin';

Vue.use(VueRouter);

export const beforeUnloadMessage = 'There are unsaved changes on this page. If you leave before saving, your changes will be lost.';

const routes = [
	{
		path: '/',
		name: 'MyCourses',
		component: MyCourses,
		meta: {
			layout: 'course'
		}
	},
	{
		path: '/classes',
		name: 'MyClasses',
		component: MyClasses,
		meta: {
			layout: 'course'
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			layout: 'login',
			open: true
		}
	},
	{
		path: '/register',
		name: 'Register',
		component: RegisterAccount,
		meta: {
			layout: 'login',
			open: true
		},
		beforeEnter: (to, from, next) => {
			Backend.get('auth').then(res => {
				const {tenant} = res.data;

				if(tenant && tenant.enableRegistration) {
					next();
				}
				else {
					next({name: 'Login'});
				}
			})
			.catch(() => next({name: 'Login'}));
		}
	},
	{
		path: '/public-course/:course',
		name: 'PublicCourse',
		beforeEnter: (to, from, next) => {
			if(!to.params.course) {
				next({name: 'Login'});
			}

			Backend.post('auth/public-course', {
				course: to.params.course
			}).then(() => Backend.get('auth').then(res => {
				Store.loaded = true;
				Store.loggedIn = res.data.loggedIn;

				if(res.data.user) {
					Store.user = res.data.user;
					Store.tenant = res.data.tenant;
				}

				next({path: '/course/' + to.params.course});
			})).catch(() => {
				next({name: 'Login'});
			});
		},
		meta: {
			open: true
		}
	},
	{
		path: '/recover-password',
		name: 'RecoverPassword',
		component: Login,
		meta: {
			layout: 'login',
			open: true,
			recoverPassword: true
		}
	},
	{
		path: '/recover-password/:token',
		name: 'RecoverPasswordToken',
		component: RecoverPasswordToken,
		meta: {
			layout: 'login',
			open: true
		}
	},
	{
		path: '/settings/lti/add-issuer/:state',
		name: 'AddIssuer',
		component: AddIssuer
	},
	{
		path: '/course/:courseId',
		name: 'Course',
		component: SingleCourse
	},
	{
		path: '/course/:courseId/:groupId',
		name: 'CourseGroup',
		component: SingleCourse
	},
	{
		path: '/review-course/:courseId',
		name: 'ReviewCourse',
		component: ReviewCourse,
		meta: {
			layout: 'review'
		},
		beforeEnter: (to, from, next) => {
			Store.courseBreadcrumbs = [];
			Store.groupBlocks = [];
			next();
		}
	},
	{
		path: '/review-course/:courseId/:groupId',
		name: 'ReviewCourseGroup',
		component: ReviewCourse,
		meta: {
			layout: 'review'
		}
	},
	...AdminRoutes
];

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		return savedPosition || {x: 0, y: 0};
	}
});

router.beforeEach((to, from, next) => {
	if(Store.editMode && Store.hasUnsavedChanges) {
		// eslint-disable-next-line no-alert
		if(!confirm(beforeUnloadMessage)) {
			Store.hasUnsavedChanges = false;
			next(from);
		}
	}

	next();
});

// Navigation guard
router.beforeEach((to, from, next) => {
	if(!Store.loaded) {
		Backend.get('auth').then(res => {
			Store.loaded = true;
			Store.loggedIn = res.data.loggedIn;

			if(res.data.tenant) {
				if(res.data.tenant.domain !== window.location.hostname) {
					const url = new URL(window.location.href);

					url.hostname = res.data.tenant.domain;
					document.location = url.href;
				}

				Store.tenant = res.data.tenant;
			}

			if(res.data.user) {
				Store.user = res.data.user;
			}

			next();
		}).catch(() => {
			Store.loaded = true;
			next();
		});
	}
	else {
		next();
	}
});

router.beforeEach((to, from, next) => {
	if(!to.meta.open && !Store.loggedIn) {
		next({name: 'Login', query: {redirect: to.fullPath}});
	}
	else if(to.name === 'Login' && Store.loggedIn) {
		next({path: to.query.redirect || '/'});
	}
	else {
		next();
	}
});

router.beforeEach((to, from, next) => {
	const validPublicRoutes = [
		'Course',
		'ReviewCourse',
		'ReviewCourseGroup',
		'CourseGroup'
	];

	if(Store.loggedIn && Store.user.isPublic && !to.meta.open && !validPublicRoutes.includes(to.name)) {
		next(new Error('Access denied.'));
	}
	else {
		next();
	}
});

export default router;