/* eslint-disable no-empty-function */
/* eslint-disable no-underscore-dangle */
const blockDefs = [
	{
		category: 'content',
		type: 'text',
		label: 'Text',
		content: {},
		meta: {
			style: 'regular'
		},
		settings: [
			{
				label: 'Text style',
				type: 'select',
				metaKey: 'style',
				options: [
					{label: 'No style', code: 'regular'},
					{label: 'Instructions', code: 'instructions'},
					{label: 'Info', code: 'info'},
					{label: 'Warning', code: 'warning'},
					{label: 'Help', code: 'help'}
				],
				reduce: style => style.code,
				multiple: false
			}
		],
		component: 'BlockText',
		editorComponent: 'EditorBlockText',
		_load: () => import('../components/blocks/BlockText'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockText')
	},
	{
		category: 'content',
		type: 'audio',
		label: 'Audio',
		content: {
			files: []
		},
		meta: {
			transcript: '',
			playbackLimit: 0
		},
		settings: [
			{
				label: 'Playback limit',
				type: 'number',
				metaKey: 'playbackLimit',
				onblur: () => {}
			}
		],
		component: 'BlockAudio',
		editorComponent: 'EditorBlockAudio',
		_load: () => import('../components/blocks/BlockAudio'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockAudio')
	},
	{
		category: 'content',
		type: 'video',
		label: 'Video',
		content: '',
		meta: {
			transcript: ''
		},
		settings: [
			{
				label: 'Align',
				id: 'align-video',
				type: 'select',
				metaKey: 'align',
				options: [
					{label: 'Align left', code: 'left'},
					{label: 'Align center', code: 'center'},
					{label: 'Align right', code: 'right'}
				],
				reduce: align => align.code,
				multiple: false
			}
		],
		component: 'BlockVideo',
		editorComponent: 'EditorBlockVideo',
		_load: () => import('../components/blocks/BlockVideo'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockVideo')
	},
	{
		category: 'content',
		type: 'image',
		label: 'Image',
		content: {
			files: []
		},
		meta: {
			align: 'left',
			maxWidth: '100%'
		},
		settings: [
			{
				label: 'Align',
				type: 'select',
				metaKey: 'align',
				options: [
					{label: 'Align left', code: 'left'},
					{label: 'Align center', code: 'center'},
					{label: 'Align right', code: 'right'}
				],
				reduce: align => align.code,
				multiple: false
			},
			{
				label: 'Max width',
				type: 'text',
				metaKey: 'maxWidth',
				placeholder: 'Ex. 100%',
				onblur: block => setMaxWidth(block)
			}
		],
		component: 'BlockImage',
		editorComponent: 'EditorBlockImage',
		_load: () => import('../components/blocks/BlockImage'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockImage')
	},
	{
		category: 'content',
		type: 'filelist',
		label: 'File list',
		content: {
			files: []
		},
		meta: {},
		settings: [],
		component: 'BlockFileList',
		editorComponent: 'EditorBlockFileList',
		_load: () => import('../components/blocks/BlockFileList'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockFileList')
	},
	{
		category: 'content',
		type: 'embedpdf',
		label: 'Embed PDF',
		content: {
			files: []
		},
		meta: {
			align: 'center',
			maxWidth: '600px'
		},
		settings: [
			{
				label: 'Align',
				type: 'select',
				metaKey: 'align',
				options: [
					{label: 'Align left', code: 'left'},
					{label: 'Align center', code: 'center'},
					{label: 'Align right', code: 'right'}
				],
				reduce: align => align.code,
				multiple: false
			},
			{
				label: 'Max width',
				type: 'text',
				metaKey: 'maxWidth',
				placeholder: 'Ex. 100%',
				onblur: block => setMaxWidth(block)
			}
		],
		component: 'BlockEmbedPdf',
		editorComponent: 'EditorBlockEmbedPdf',
		_load: () => import('../components/blocks/BlockEmbedPdf'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockEmbedPdf')
	},
	{
		category: 'question',
		type: 'options',
		label: 'Options',
		content: '',
		meta: {
			sortOptions: 'manual',
			scoreOptions: 'all',
			maxScore: 1
		},
		settings: [
			{
				label: 'Allow multiple answers',
				type: 'checkbox',
				metaKey: 'allowMultiple'
			},
			{
				label: 'Sort options',
				type: 'select',
				metaKey: 'sortOptions',
				options: [
					{label: 'Manual', code: 'manual'},
					{label: 'Alphabetical', code: 'alpha'},
					{label: 'Random', code: 'random'}
				],
				reduce: sortOptions => sortOptions.code,
				multiple: false
			},
			{
				label: 'Score options',
				type: 'select',
				metaKey: 'scoreOptions',
				options: [
					{label: 'Only give points if all correct answers are checked', code: 'all'},
					{label: 'Each correct answer awards points', code: 'each'},
					{label: 'One correct answer awards points', code: 'one'}
				],
				reduce: sortOptions => sortOptions.code,
				multiple: false
			},
			{
				label: 'Score',
				type: 'number',
				metaKey: 'maxScore',
				onblur: () => {}
			}
		],
		component: 'BlockOptions',
		editorComponent: 'EditorBlockOptions',
		_load: () => import('../components/blocks/BlockOptions'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockOptions')
	},
	{
		category: 'question',
		type: 'freetext',
		label: 'Free text',
		content: '',
		meta: {
			maxScore: 1
		},
		settings: [
			{
				label: 'Score',
				type: 'number',
				metaKey: 'maxScore',
				onblur: () => {}
			}
		],
		component: 'BlockFreeText',
		editorComponent: 'EditorBlockFreeText',
		_load: () => import('../components/blocks/BlockFreeText'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockFreeText')
	},
	{
		category: 'question',
		type: 'recaudio',
		label: 'Record audio',
		content: '',
		meta: {
			timeLimit: 120,
			nrOfTries: 1,
			maxScore: 1
		},
		settings: [
			{
				label: 'Time limit (seconds)',
				type: 'number',
				metaKey: 'timeLimit',
				onblur: () => {}
			},
			{
				label: 'Nr. of tries',
				type: 'number',
				metaKey: 'nrOfTries',
				onblur: () => {}
			},
			{
				label: 'Score',
				type: 'number',
				metaKey: 'maxScore',
				onblur: () => {}
			}
		],
		component: 'BlockRecordAudio',
		editorComponent: 'EditorBlockRecordAudio',
		_load: () => import('../components/blocks/BlockRecordAudio'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockRecordAudio')
	},
	{
		category: 'question',
		type: 'recvideo',
		label: 'Record video',
		content: '',
		meta: {
			timeLimit: 120,
			nrOfTries: 1,
			maxScore: 1
		},
		settings: [
			{
				label: 'Time limit (seconds)',
				type: 'number',
				metaKey: 'timeLimit',
				onblur: () => {}
			},
			{
				label: 'Nr. of tries',
				type: 'number',
				metaKey: 'nrOfTries',
				onblur: () => {}
			},
			{
				label: 'Score',
				type: 'number',
				metaKey: 'maxScore',
				onblur: () => {}
			}
		],
		component: 'BlockRecordVideo',
		editorComponent: 'EditorBlockRecordVideo',
		_load: () => import('../components/blocks/BlockRecordVideo'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockRecordVideo')
	},
	{
		category: 'question',
		type: 'opencloze',
		label: 'Open Cloze',
		content: {},
		meta: {
			sortOptions: 'manual',
			maxScore: 1,
			freetextAnswers: false
		},
		settings: [
			{
				label: 'Sort options',
				type: 'select',
				metaKey: 'sortOptions',
				options: [
					{label: 'Manual', code: 'manual'},
					{label: 'Alphabetical', code: 'alpha'},
					{label: 'Random', code: 'random'}
				],
				reduce: sortOptions => sortOptions.code,
				multiple: false
			},
			{
				label: 'Freetext answers',
				type: 'checkbox',
				metaKey: 'freetextAnswers'
			},
			{
				label: 'Score per gap',
				type: 'number',
				metaKey: 'maxScore',
				onblur: () => {}
			}
		],
		component: 'BlockOpenCloze',
		editorComponent: 'EditorBlockOpenCloze',
		_load: () => import('../components/blocks/BlockOpenCloze'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockOpenCloze')
	},
	{
		category: 'question',
		type: 'boolean',
		label: 'True/False',
		content: '',
		meta: {
			maxScore: 1
		},
		settings: [
			{
				label: 'Score',
				type: 'number',
				metaKey: 'maxScore',
				onblur: () => {}
			}
		],
		component: 'BlockBoolean',
		editorComponent: 'EditorBlockBoolean',
		_load: () => import('../components/blocks/BlockBoolean'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockBoolean')
	},
	{
		category: 'question',
		type: 'files',
		label: 'File upload',
		content: '',
		meta: {
			allowedFileTypes: [],
			maxScore: 1,
			maxFiles: 1
		},
		settings: [
			{
				label: 'Allowed file types',
				metaKey: 'allowedFileTypes',
				type: 'select',
				options: [
					{
						label: 'Word',
						code: 'doc',
						mimeTypes: [
							'application/msword',
							'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
						]
					},
					{
						label: 'Excel',
						code: 'xls',
						mimeTypes: [
							'application/vnd.ms-excel',
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						]
					},
					{
						label: 'PDF',
						code: 'pdf',
						mimeTypes: ['application/pdf']
					},
					{
						label: 'OpenDocument Text',
						code: 'odt',
						mimeTypes: ['application/vnd.oasis.opendocument.text']
					},
					{
						label: 'OpenDocument Spreadsheet',
						code: 'ods',
						mimeTypes: ['application/vnd.oasis.opendocument.spreadsheet']
					},
					{
						label: 'OpenDocument Formula',
						code: 'odf',
						mimeTypes: ['application/vnd.oasis.opendocument.formula']
					},
					{
						label: 'Rich Text Format',
						code: 'rtf',
						mimeTypes: ['application/rtf']
					}
				],
				reduce: allowedFileTypes => allowedFileTypes,
				multiple: true
			},
			{
				label: 'Max nr of files',
				metaKey: 'maxFiles',
				type: 'range',
				min: 1,
				max: 8
			},
			{
				label: 'Score',
				type: 'number',
				metaKey: 'maxScore',
				onblur: () => {}
			}
		],
		component: 'BlockFileUpload',
		editorComponent: 'EditorBlockFileUpload',
		_load: () => import('../components/blocks/BlockFileUpload'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockFileUpload')
	},
	{
		category: 'question',
		type: 'sort',
		label: 'Sort objects',
		content: '',
		meta: {
			maxScore: 1
		},
		settings: [
			{
				label: 'Score',
				type: 'number',
				metaKey: 'maxScore',
				onblur: () => {}
			}
		],
		component: 'BlockSortObjects',
		editorComponent: 'EditorBlockSortObjects',
		_load: () => import('../components/blocks/BlockSortObjects'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockSortObjects')
	},
	{
		category: 'question',
		type: 'pair',
		label: 'Pair objects',
		content: '',
		meta: {
			scoreOptions: 'all',
			maxScore: 1
		},
		settings: [
			{
				label: 'Score options',
				type: 'select',
				metaKey: 'scoreOptions',
				options: [
					{label: 'Only give points if all correct answers are checked', code: 'all'},
					{label: 'Each correct answer awards points', code: 'each'}
				],
				reduce: sortOptions => sortOptions.code,
				multiple: false
			},
			{
				label: 'Score',
				type: 'number',
				metaKey: 'maxScore',
				onblur: () => {}
			}
		],
		component: 'BlockPairObjects',
		editorComponent: 'EditorBlockPairObjects',
		_load: () => import('../components/blocks/BlockPairObjects'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockPairObjects')
	},
	{
		category: 'question',
		type: 'group',
		label: 'Group objects',
		content: '',
		meta: {
			scoreOptions: 'all',
			maxScore: 1
		},
		settings: [
			{
				label: 'Score options',
				type: 'select',
				metaKey: 'scoreOptions',
				options: [
					{label: 'Only give points if all correct answers are checked', code: 'all'},
					{label: 'Each correct answer awards points', code: 'each'}
				],
				reduce: sortOptions => sortOptions.code,
				multiple: false
			},
			{
				label: 'Score',
				type: 'number',
				metaKey: 'maxScore',
				onblur: () => {}
			}
		],
		component: 'BlockGroupObjects',
		editorComponent: 'EditorBlockGroupObjects',
		_load: () => import('../components/blocks/BlockGroupObjects'),
		_loadEditor: () => import('../components/editor-blocks/EditorBlockGroupObjects')
	}
];

export const loadBlocks = () => {
	const blocks = {};

	for(const def of blockDefs) {
		blocks[def.component] = def._load;
	}

	return blocks;
};

export const loadEditorBlocks = () => {
	const blocks = {};

	for(const def of blockDefs) {
		blocks[def.editorComponent] = def._loadEditor;
	}

	return blocks;
};

// eslint-disable-next-line require-jsdoc
function setMaxWidth(block) {
	if(block.meta.maxWidth && block.meta.maxWidth.length) {
		let maxWidth = block.meta.maxWidth.replace(/\D+/u, '');

		if(block.meta.maxWidth.includes('%')) {
			maxWidth += '%';
		}
		else if(maxWidth.length) {
			maxWidth += 'px';
		}

		block.meta.maxWidth = maxWidth;
	}
}

export default blockDefs;