import Bus from '../inc/bus';
import {debounce} from 'vue-debounce';

export default {
	data() {
		return {
			showMenu: false
		};
	},
	created() {
		const dFn = this.onResize();

		dFn();

		window.addEventListener('resize', dFn);

		Bus.on('clickAnywhere', e => {
			if(!e.target.closest('.sidebar') && window.innerWidth < 1200) {
				this.showMenu = false;
			}
		});
	},
	destroyed() {
		window.removeEventListener('resize', this.onResize());
	},
	methods: {
		onResize() {
			return debounce(() => {
				this.showMenu = window.innerWidth > 1200;
			}, 100);
		},
		toggleMenu() {
			this.showMenu = !this.showMenu;
		}
	}
};