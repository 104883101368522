/* eslint-disable require-jsdoc */
import Vue from 'vue';

/**
 * Accepts an array that will be shuffled and returned. Does not modify the input array.
 *
 * @param {Array} array The array to shuffle
 * @returns {Array} The shuffled array
 */
export function shuffleArray(array) {
	for(let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));

		// eslint-disable-next-line array-element-newline
		[array[i], array[j]] = [array[j], array[i]];
	}

	return array;
}

export function addDefaults(object, defaults, reactive = false) {
	if(typeof object !== 'object' || typeof defaults !== 'object') {
		throw new Error('Expected two objects');
	}

	for(const key in defaults) {
		if(object.hasOwnProperty(key) && object[key] !== '') {
			continue;
		}

		const newValue = (typeof defaults[key] === 'object' ? JSON.parse(JSON.stringify(defaults[key])) : defaults[key]);

		if(reactive) {
			Vue.set(object, key, newValue);
		}
		else {
			object[key] = newValue;
		}
	}
}

export function handleFileDownload(res) {
	const url = URL.createObjectURL(new Blob([res.data]));
	const link = document.createElement('a');
	const filename = res.headers['x-suggested-filename'] || 'file.xlsx';

	link.href = url;
	link.setAttribute('download', filename);

	document.body.appendChild(link);
	link.click();
}