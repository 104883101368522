<template>
	<div class="review-course view">
		<ReviewCourseSidebar @change="changedSidebar" />

		<main class="content">
			<header>
				<div class="headline">
					Review {{ pageTitle }}
				</div>
				<div v-if="isCourse" class="total">
					Total progress: {{ courseProgress.progress }}%
				</div>
				<div class="total">
					Total score: {{ totalScore }} / {{ maxScore }} ({{ scorePercent }}%)
				</div>
				<div v-if="cefrScore" class="cefr">
					Suggested CEFR score: {{ cefrScore }}
				</div>

				<div class="downloads">
					<BaseButton v-if="isCourse" type="link" @click="downloadResults">
						<IconDownload :width="24" :height="24" />
						<span>Download results</span>
					</BaseButton>

					<BaseButton v-if="isCourse" type="link" @click="downloadCertificate">
						<IconDownload :width="24" :height="24" />
						<span>Download certificate</span>
					</BaseButton>
				</div>
			</header>

			<ReviewOverview v-if="isCourse" :course="courseId" :session="session.id" />
			<ReviewBlockArea v-else />
			<NoticeArea />

			<div class="content-footer">
				<BrandLogo />

				<nav>
					<BaseButton v-if="showPrev" type="secondary" @click="previousGroup">
						<IconArrowLeft :width="16" :height="16" />
						<span>Previous</span>
					</BaseButton>
					<BaseButton v-if="showNext" type="secondary" @click="nextGroup">
						<span>Next</span>
						<IconArrowRight :width="16" :height="16" />
					</BaseButton>
				</nav>
			</div>
		</main>
	</div>
</template>

<script>
	import ReviewCourseSidebar from '../components/ReviewCourseSidebar';
	import ReviewBlockArea from '../components/ReviewBlockArea';
	import ReviewOverview from '../components/ReviewOverview';
	import NoticeArea from '../components/NoticeArea';
	import BrandLogo from '../components/BrandLogo';
	import BaseButton from '../components/BaseButton';
	import IconArrowLeft from '../components/icons/IconArrowLeft';
	import IconArrowRight from '../components/icons/IconArrowRight';
	import IconDownload from '../components/icons/IconDownload';
	import {fetchGroupBlocks, getNext, updateProgress} from '../inc/courseUtils';
	import Store from '../inc/store';
	import courseMixin from '../mixins/courseMixin';
	import {jsonToHtml} from '../inc/text';
	import Backend from '../inc/backend';
	import {handleFileDownload} from '../inc/utils';

	export default {
		components: {
			ReviewCourseSidebar,
			ReviewBlockArea,
			ReviewOverview,
			NoticeArea,
			BrandLogo,
			BaseButton,
			IconArrowLeft,
			IconArrowRight,
			IconDownload
		},
		mixins: [courseMixin],
		computed: {
			answers() {
				return Store.groupAnswers || [];
			},
			progress() {
				return Store.courseGroupProgress || {};
			},
			courseProgress() {
				return Store.courseProgress || {};
			},
			session() {
				return Store.session;
			},
			showNext() {
				return this.course && this.groupId && !!getNext(this.groupId, 1, true);
			},
			showPrev() {
				return this.course && this.groupId && !!getNext(this.groupId, -1, true);
			},
			totalScore() {
				if(this.isCourse) {
					return this.courseProgress.score;
				}

				return this.answers.reduce((acc, cur) => acc + cur.score, 0);
			},
			maxScore() {
				return this.isCourse ? this.courseProgress.maxScore : this.progress.maxScore;
			},
			scorePercent() {
				return Math.round(this.totalScore / Math.max(1, this.maxScore) * 100);
			},
			cefrScore() {
				if(this.group.meta && this.group.meta.cefr && this.group.meta.cefr.enable) {
					for(const key in this.group.meta.cefr) {
						const {min, max} = this.group.meta.cefr[key];

						if(key !== 'enable' && this.scorePercent && (this.scorePercent >= min) && (this.scorePercent <= max)) {
							return key.replace('p', '+').toUpperCase();
						}
					}
				}

				return '';
			},
			isCourse() {
				return this.groupId === this.courseId;
			}
		},
		methods: {
			nextGroup() {
				const groupId = getNext(this.groupId, 1, true);

				if(groupId) {
					this.$router.push({name: 'ReviewCourseGroup', params: {courseId: this.course.id, groupId}});
				}
			},
			previousGroup() {
				const groupId = getNext(this.groupId, -1, true);

				if(groupId) {
					if(groupId === this.courseId) {
						this.$router.push({name: 'ReviewCourse', params: {courseId: groupId}});
					}
					else {
						this.$router.push({name: 'ReviewCourseGroup', params: {courseId: this.course.id, groupId}});
					}
				}
			},
			nextWithBlocks() {
				if(this.group && this.group.numBlocks) {
					return true;
				}

				const groupId = getNext(this.groupId, 1, true);

				if(groupId) {
					this.$router.replace({name: 'ReviewCourseGroup', params: {courseId: this.courseId, groupId}});
				}

				return false;
			},
			loadGroupData(groupId) {
				if(groupId === this.courseId) {
					updateProgress(groupId);

					return;
				}

				if(this.nextWithBlocks()) {
					this.setBreadcrumbs(groupId);

					updateProgress(groupId);
					fetchGroupBlocks(groupId);
				}
			},
			getQuestionText(content) {
				return jsonToHtml(content);
			},
			downloadResults() {
				Backend.get(`/sessions/${this.session.id}/download`, {responseType: 'blob'})
					.then(handleFileDownload);
			},
			downloadCertificate() {
				Backend.get(`/sessions/${this.session.id}/download-certificate`, {responseType: 'blob'})
					.then(handleFileDownload);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.review-course {
		flex: 1;
		display: flex;
		flex-flow: column;
	}

	header::v-deep {
		.button {
			margin-top: $default_padding;
		}
	}

	.downloads {
		display: flex;
		flex-flow: row wrap;
		gap: $default_padding;
	}

	.content::v-deep {
		flex: 1;
		display: flex;
		flex-flow: column;

		& > header {
			padding: $default_padding $default_padding * 2;
			border-bottom: $border;

			@include breakpoint-min('small') {
				padding: $default_padding * 2 $default_padding * 4;
			}

			.headline {
				font-size: 1.625rem;
				line-height: 1.5em;
			}
		}

		p,
		ol,
		ul {
			margin-bottom: $line__height;

			&:last-child {
				margin-bottom: 0;
			}
		}

		table p {
			margin-bottom: 0;
		}

		.block-inner {
			input, select, textarea, .options label, .bool-input, .items li, &.disabled li {
				user-select: none;
				pointer-events: none;
				cursor: pointer;
			}
		}
	}

	.content-footer {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		border-top: $border;
		padding: $default_padding $default_padding * 2;

		@include breakpoint-min('small') {
			padding: $default_padding * 2 $default_padding * 4;
		}

		.logo-wrapper {
			padding: 0;
		}
	}

	nav {
		display: flex;
		flex-flow: row nowrap;

		.button::v-deep {
			margin-left: 10px;
		}
	}

	.notices::v-deep {
		position: fixed;
		left: calc(#{$sidebar__width_active} + #{$site__padding});
		bottom: $site__padding;
		z-index: 100;
	}
</style>