import Backend from '../inc/backend';
import {clearCache, fetchGroupBlocks, getAncestors, getGroup, getNext, setCache, updateProgress} from '../inc/courseUtils';
import Store from '../inc/store';

export default {
	computed: {
		course() {
			return Store.course;
		},
		group() {
			return Store.courseGroup;
		},
		loaded() {
			return !!this.course.id;
		},
		courseId() {
			return this.$route.params.courseId;
		},
		groupId() {
			return this.$route.params.groupId || this.courseId;
		},
		pageTitle() {
			return this.group ? this.group.name : this.course.name;
		}
	},
	watch: {
		courseId() {
			this.fetchCourse();
		},
		groupId(id) {
			if(this.loaded) {
				Store.courseGroup = getGroup(id);
			}
		},
		course() {
			if(this.groupId) {
				this.setBreadcrumbs(this.groupId);
			}
		},
		loaded(loaded) {
			if(loaded) {
				Store.courseGroup = getGroup(this.groupId);
			}
		},
		group(group) {
			this.loadGroupData(group.id);
		}
	},
	created() {
		this.fetchCourse();
	},
	destroyed() {
		clearCache();

		Store.course = {};
		Store.courseBreadcrumbs = [];
		Store.groupBlocks = [];
		Store.groupAnswers = [];
		Store.editMode = false;
	},
	beforeRouteEnter(to, _, next) {
		if(Store.session && Store.session.course === to.params.courseId) {
			next();
		}
		else {
			Backend.get(`course-session/${to.params.courseId}`)
				.then(res => {
					Store.session = res.data;
					next();
				}).catch(() => {
					next('/');
				});
		}
	},
	methods: {
		nextWithBlocks() {
			if(this.group.numBlocks || this.editMode) {
				return true;
			}

			const groupId = getNext(this.groupId, 1, true);

			if(groupId) {
				this.$router.replace({name: 'CourseGroup', params: {courseId: this.courseId, groupId}});
			}

			return false;
		},
		loadGroupData(groupId) {
			if(this.nextWithBlocks()) {
				this.setBreadcrumbs(groupId);

				if(!this.editMode) {
					updateProgress(groupId);
				}

				const hasTimeLimit = Boolean(this.groupSettings.timeLimit && this.groupSettings.timeLimit.value > 0);

				if(!hasTimeLimit || this.editMode) {
					fetchGroupBlocks(groupId);
				}
			}
		},
		fetchCourse() {
			Backend.get('courses/' + this.$route.params.courseId).then(res => {
				setCache(res.data);
				Store.course = res.data;
			});
		},
		changedSidebar() {
			setCache(this.course);
			this.setBreadcrumbs(this.groupId);
		},
		setBreadcrumbs(groupId) {
			if(!this.course.children) {
				return;
			}

			// Replace breadcrumbs
			Store.courseBreadcrumbs.splice(0, Store.courseBreadcrumbs.length, ...getAncestors(groupId));
		}
	}
};