<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" transform="translate(0.5 0.5)" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M16.66 8.88l2.12 2.12-2.12 2.12" :stroke="fill" /><path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M13.12 5.34l-2.12-2.12-2.12 2.12" :stroke="fill" /><path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M8.88 16.66l2.12 2.12 2.12-2.12" :stroke="fill" /><path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M5.34 13.12l-2.12-2.12 2.12-2.12" :stroke="fill" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>