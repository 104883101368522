<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><line fill="none" stroke-miterlimit="10" x1="1" y1="22" x2="9" y2="22" /> <rect x="1" y="3" fill="none" stroke-miterlimit="10" width="8" height="26" :stroke="fill" /> <line fill="none" stroke-miterlimit="10" x1="5" y1="8" x2="5" y2="12" /> <line fill="none" stroke-miterlimit="10" x1="9" y1="22" x2="17" y2="22" /> <rect x="9" y="3" fill="none" stroke-miterlimit="10" width="8" height="26" :stroke="fill" /> <line fill="none" stroke-miterlimit="10" x1="13" y1="8" x2="13" y2="12" /> <line fill="none" stroke-miterlimit="10" x1="21.023" y1="22.082" x2="28.929" y2="20.858" /> <rect x="20.057" y="2.541" transform="matrix(0.9882 -0.1531 0.1531 0.9882 -2.0951 3.8651)" fill="none" stroke-miterlimit="10" width="8" height="26" :stroke="fill" /> <line fill="none" stroke-miterlimit="10" x1="22.833" y1="7.635" x2="23.445" y2="11.588" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>