<template>
	<div class="modal-inner">
		<IconCross :width="16" :height="16" class="close" @click.native="close" />
		<h2>CEFR scores</h2>
		<div class="modal-content">
			<p>
				<BoolInput :checked="group.meta.cefr.enable" label="Enable CEFR grading" @input="checked => group.meta.cefr.enable = checked" />
			</p>

			<p>
				<label for="cefr_b0_min">B0</label>
				<input id="cefr_b0_min" v-model="group.meta.cefr.b0.min" type="number" min="0" max="100" />
				<span> - </span>
				<input id="cefr_b0_max" v-model="group.meta.cefr.b0.max" type="number" min="0" max="100" />
			</p>

			<p>
				<label for="cefr_b1_min">B1</label>
				<input id="cefr_b1_min" v-model="group.meta.cefr.b1.min" type="number" min="0" max="100" />
				<span> - </span>
				<input id="cefr_b1_max" v-model="group.meta.cefr.b1.max" type="number" min="0" max="100" />
			</p>

			<p>
				<label for="cefr_b2_min">B2</label>
				<input id="cefr_b2_min" v-model="group.meta.cefr.b2.min" type="number" min="0" max="100" />
				<span> - </span>
				<input id="cefr_b2_max" v-model="group.meta.cefr.b2.max" type="number" min="0" max="100" />
			</p>

			<p>
				<label for="cefr_b2p_min">B2+</label>
				<input id="cefr_b2p_min" v-model="group.meta.cefr.b2p.min" type="number" min="0" max="100" />
				<span> - </span>
				<input id="cefr_b2p_max" v-model="group.meta.cefr.b2p.max" type="number" min="0" max="100" />
			</p>

			<p>
				<label for="cefr_c1_min">C1</label>
				<input id="cefr_c1_min" v-model="group.meta.cefr.c1.min" type="number" min="0" max="100" />
				<span> - </span>
				<input id="cefr_c1_max" v-model="group.meta.cefr.c1.max" type="number" min="0" max="100" />
			</p>

			<p>
				<label for="cefr_c1p_min">C1+</label>
				<input id="cefr_c1p_min" v-model="group.meta.cefr.c1p.min" type="number" min="0" max="100" />
				<span> - </span>
				<input id="cefr_c1p_max" v-model="group.meta.cefr.c1p.max" type="number" min="0" max="100" />
			</p>
		</div>
		<div class="modal-footer">
			<BaseButton type="primary" @click="close">
				Done
			</BaseButton>
		</div>
	</div>
</template>

<script>
	import IconCross from './icons/IconCross';
	import BaseButton from './BaseButton';
	import BoolInput from './BoolInput';

	export default {
		name: 'CefrModal',
		components: {
			IconCross,
			BaseButton,
			BoolInput
		},
		props: {
			group: Object
		},
		beforeMount() {
			if(!this.group.meta.cefr) {
				this.$set(this.group.meta, 'cefr', {
					enable: false,
					b0: {
						min: 0,
						max: 19
					},
					b1: {
						min: 20,
						max: 30
					},
					b2: {
						min: 31,
						max: 49
					},
					b2p: {
						min: 50,
						max: 60
					},
					c1: {
						min: 61,
						max: 80
					},
					c1p: {
						min: 81,
						max: 100
					}
				});
			}
		},
		methods: {
			close() {
				this.$modal.hide('CefrModal');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.modal-content {
		margin-bottom: $default_padding;
	}

	p {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		column-gap: 10px;
		margin-bottom: $default_padding;

		label {
			flex: 1 0 100%;
		}

		input {
			flex: 1;
			margin-bottom: 0;
		}
	}
</style>