<template>
	<div class="class-code-modal">
		<label for="class-code-modal-code">Enter class code</label>
		<input id="class-code-modal-code" v-model="code" type="text" maxlength="8" />

		<BaseButton type="primary" @click="joinClass">
			Join
		</BaseButton>

		<BaseButton type="secondary" @click="$modal.hideAll()">
			Cancel
		</BaseButton>
	</div>
</template>

<script>
	import BaseButton from './BaseButton';

	export default {
		name: 'ClassCodeModal',
		components: {
			BaseButton
		},
		props: {
			handleJoin: {
				type: Function,
				required: true
			}
		},
		data() {
			return {
				code: ''
			};
		},
		methods: {
			joinClass() {
				this.handleJoin(this.code.toUpperCase()).then(() => this.$modal.hideAll());
			}
		}
	};
</script>

<style lang="scss" scoped>
	label {
		display: block;
		font-size: 1.25em;
		margin-bottom: 1em;
	}

	input {
		font-family: monospace;
		letter-spacing: 1px;
		margin-bottom: $default_padding;
		text-transform: uppercase;
	}
</style>