<template>
	<article :class="classes">
		<component :is="blockDef.component" ref="block" :block.sync="block" v-on="$listeners">
			<slot />
		</component>
		<template v-if="showFeedback">
			<div v-if="!answer" class="feedback no-answer">
				<IconCross :width="16" :height="16" class="icon-failed" aria-label="Failed" />
				<span v-if="block.meta.maxScore > 1" class="score">0 / {{ block.meta.maxScore }}</span>
				<span v-else class="score">Incorrect</span>
			</div>
			<div v-else-if="hasScore" class="feedback">
				<IconLock v-if="isLocked" :width="16" :height="16" class="icon-locked" aria-label="Locked" />
				<IconCheck v-if="answer.score > 0" :width="16" :height="16" class="icon-passed" aria-label="Passed" />
				<IconCross v-else :width="16" :height="16" class="icon-failed" aria-label="Failed" />
				<span v-if="showScore" class="score">{{ answer.score }} / {{ answer.maxScore }}</span>
				<span v-else class="score">{{ answer.score ? 'Correct' : 'Incorrect' }}</span>
			</div>
			<div v-else class="feedback">
				<span class="score"><i>Under review</i></span>
			</div>
		</template>
	</article>
</template>

<script>
	import blockDefs, {loadBlocks} from '../inc/blockDefinitions';
	import Store from '../inc/store';
	import {isCourseComplete, showCourseFeedback} from '../inc/courseUtils';
	import IconCheck from './icons/IconCheck';
	import IconCross from './icons/IconCross';
	import IconLock from './icons/IconLock';

	export default {
		components: {
			...loadBlocks(),
			IconCheck,
			IconCross,
			IconLock
		},
		props: {
			block: {
				type: Object,
				required: true
			},
			nextBlock: {
				type: Object,
				required: false
			}
		},
		computed: {
			classes() {
				return [
					'block',
					this.block.type,
					this.blockDef.category,
					(typeof this.block.meta.style === 'string' ? (this.block.meta.style + '-style') : 'regular-style'),
					this.isLocked ? 'locked' : ''
				];
			},
			blockDef() {
				return blockDefs.find(b => b.type === this.block.type);
			},
			answer() {
				return Store.groupAnswers.find(ca => ca.blockId === this.block.id);
			},
			showFeedback() {
				return showCourseFeedback(this.block.type);
			},
			hasScore() {
				return this.answer && (this.answer.score !== null);
			},
			isComplete() {
				return isCourseComplete();
			},
			isLocked() {
				return !!Store.courseGroupProgress.timeGraded;
			},
			showScore() {
				return this.hasScore && this.answer.maxScore > 1;
			},
			group() {
				return Store.courseGroup;
			}
		}
	};
</script>

<style lang="scss" scoped>
	article {
		position: relative;

		&.locked {
			pointer-events: none;
		}

		&.regular-style {
			border-top: $border;
		}

		& > .block-inner {
			padding: $site__padding $site__padding * 2;
		}

		& > .feedback {
			padding: 0 $site__padding * 2 $site__padding;
		}

		&::v-deep header {
			margin-bottom: 1.5rem;
			position: relative;
		}

		&.question::v-deep {
			header {
				@include question-counter();
			}
		}

		&:first-child,
		&.content + .regular-style.content,
		&.text:not(.regular-style) + article {
			border-top: none;
		}

		&.content.regular-style  + .regular-style.content {
			& > * {
				padding-top: 0;
			}
		}
	}

	.feedback {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		& > svg {
			margin-right: 5px;

			&.icon-locked {
				margin-right: 10px;
			}
		}
	}

	.icon-passed,
	.icon-passed ~ span {
		color: $color__green;
	}

	.icon-failed,
	.icon-failed ~ span {
		color: $color__red;
	}

	.cefr {
		margin-left: $default_padding;
	}

	@include breakpoint('small') {
		article {
			& > .block-inner {
				padding: $site__padding;
			}

			&.question::v-deep {
				header {
					position: static;

					&::before {
						top: $default_padding * 0.5;
						left: $default_padding * 0.5;
						transform: none;
						font-size: 2em;
					}
				}
			}
		}
	}
</style>