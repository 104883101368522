<template>
	<div class="overview-wrapper">
		<ul>
			<li v-for="group in groupScores" :key="group.id" class="box">
				<div class="group-content">
					<h3>{{ group.name }}</h3>

					<table v-if="group.timeDone">
						<tbody>
							<tr>
								<th scope="row">
									Completed:
								</th>
								<td>{{ formatTime(group.timeDone) }}</td>
							</tr>

							<tr>
								<th scope="row">
									Score:
								</th>
								<td>{{ group.score }} / {{ group.maxScore }} ({{ Math.round(group.score / group.maxScore * 100) }}%)</td>
							</tr>

							<tr v-if="group.cefrScore">
								<th scope="row">
									CEFR score:
								</th>
								<td>{{ group.cefrScore }}</td>
							</tr>
						</tbody>
					</table>
					<p v-else>
						Not completed
					</p>
				</div>

				<BaseButton :href="{ name: 'ReviewCourseGroup', params: { courseId, groupId: group.id } }">
					Show
				</BaseButton>
			</li>
		</ul>
	</div>
</template>

<script>
	import {DateTime} from 'luxon';
	import Store from '../inc/store';
	import BaseButton from './BaseButton.vue';
	import Backend from '../inc/backend';

	/* eslint-disable no-underscore-dangle */
	export default {
		components: {
			BaseButton
		},
		props: {
			course: {
				type: String,
				required: true
			},
			session: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				groupScores: []
			};
		},
		computed: {
			courseId() {
				return Store.course.id;
			}
		},
		created() {
			Backend.get(`/sessions/${this.session}/scores`)
				.then(res => {
					this.groupScores = res.data;
				});
		},
		methods: {
			formatTime(timestamp) {
				return DateTime.fromMillis(timestamp).toFormat('LLL dd y HH:mm');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.overview-wrapper {
		display: flex;
		flex-flow: column nowrap;
		flex: 1;
		padding: $default_padding $default_padding * 2;

		@include breakpoint-min('small') {
			padding: $default_padding * 2 $default_padding * 4;
		}
	}

	h3 {
		font-size: 20px;
		margin-bottom: 0.5em;
	}

	table {
		margin: 0;

		th, td {
			border: none;
			padding: 0;

			@media (max-width: 535px) {
				display: block;
			}
		}

		th {
			padding: 0 15px 5px 0;
			font-weight: $font__semibold;
		}

		@media (max-width: 535px) {
			th {
				padding-right: 0;
			}

			td {
				padding-bottom: 15px;
			}
		}
	}

	ul {
		list-style-type: none;
		padding: 0;

		li {
			display: flex;
			flex-flow: column nowrap;
			justify-content: space-between;
			align-items: flex-start;
			gap: $default_padding;
			margin-bottom: $default_padding;
			padding: $default_padding;
			box-shadow: $box_shadow;
			background: $color__white;
			border-radius: $border_radius;

			@media (min-width: 535px) {
				flex-flow: row nowrap;
				align-items: center;
			}
		}

		@include breakpoint-min('large') {
			display: flex;
			flex-flow: row wrap;
			column-gap: $default_padding;

			li {
				flex: 1 0 calc(50% - #{$default_padding});
				max-width: calc(50% - #{$default_padding});
			}
		}
	}
</style>