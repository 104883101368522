<template>
	<li>
		<router-link :to="{ name: routeName, params: { courseId: course.id, groupId: node.id } }" tag="span" class="content">
			<span class="text">
				{{ node.name }}
			</span>
		</router-link>

		<transition name="collapse">
			<ol v-if="active">
				<CourseTreeNode v-for="child in node.children" :key="child.id" :mode="mode" :node="child" />
			</ol>
		</transition>
	</li>
</template>

<script>
	import Store from '../inc/store';

	export default {
		name: 'CourseTreeNode',
		props: {
			node: {
				type: Object,
				required: true
			},
			mode: {
				type: String,
				default: 'default'
			}
		},
		computed: {
			course() {
				return Store.course;
			},
			active() {
				return Store.courseBreadcrumbs.includes(this.node.id);
			},
			routeName() {
				if(this.mode === 'review') {
					return 'ReviewCourseGroup';
				}

				return 'CourseGroup';
			}
		}
	};
</script>

<style lang="scss" scoped>
	$row_height: $font__size * 2;
	$border_color: rgba($color__white, 0.3);
	$active_background_color: rgba($color__white, 0.1);

	li {
		display: flex;
		flex-wrap: wrap;
		line-height: $row_height;
		color: $color__semi_white2;
	}

	.content {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		line-height: $row_height;
		position: relative;
		width: 100px; /* No idea why this works, but otherwise long texts will get broken. */

		.text {
			flex: 1 0 1em;
		}

		svg {
			align-self: center;
			justify-self: center;
		}
	}

	ol ol {
		width: 100%;
		list-style: none;
		padding-left: $site__padding/4;

		ol {
			padding-left: $site__padding/2;
		}

		li {
			padding-left: $site__padding/4;
			border-left: 1px solid $border_color;

			&::before {
				content: '';
				position: relative;
				height: $row_height/2;
				width: $site__padding/4;
				color: $color__dark;
				border-bottom: 1px solid $border_color;
				display: inline-block;
				left: -$site__padding/4;
			}

			&:last-child {
				border-left: none;

				&::before {
					border-left: 1px solid $border_color;
				}
			}
		}
	}

	.text {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.active {
		color: $color__white;
	}

	.highlight:not(.draggable-ghost) {
		border-top-width: $row_height/2;
		border-bottom-width: $row_height/2;
	}

	.router-link-exact-active {
		& > span {
			color: #fff;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			z-index: -1;
			pointer-events: none;
			top: 0;
			right: -40px;
			width: $sidebar__width_active;
			border-top: $row_height/2 solid $active_background_color;
			border-bottom: $row_height/2 solid $active_background_color;
		}
	}
</style>