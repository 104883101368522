<template>
	<div class="register view">
		<NoticeArea />

		<BaseCard title="Register">
			<form method="post" @submit.prevent="register">
				<label for="email">Email address</label>
				<input id="email" v-model="user.email" type="email" required />

				<label for="firstName">First name</label>
				<input id="firstName" v-model="user.firstName" type="text" required />

				<label for="lastName">Last name</label>
				<input id="lastName" v-model="user.lastName" type="text" required />

				<label for="password">Password</label>
				<input id="password" v-model="user.password" type="password" placeholder="At least 12 characters" required />

				<label for="passwordConfirm">Confirm password</label>
				<input id="passwordConfirm" v-model="user.passwordConfirm" type="password" required />

				<label for="classCode">Class code</label>
				<input id="classCode" v-model="classCode" type="text" placeholder="Ex. ABCD1234" required />

				<div class="buttons">
					<BaseButton type="link" :href="{ name: 'Login' }" class="left">
						Back to login
					</BaseButton>
					<BaseButton type="submit">
						Register
					</BaseButton>
				</div>
			</form>
		</BaseCard>
	</div>
</template>

<script>
	import BaseCard from '../components/BaseCard.vue';
	import BaseButton from '../components/BaseButton.vue';
	import Backend from '../inc/backend';
	import Bus from '../inc/bus';

	export default {
		components: {
			BaseCard,
			BaseButton
		},
		data() {
			return {
				user: {},
				classCode: ''
			};
		},
		methods: {
			register() {
				if(!Object.values(this.user).length) {
					return;
				}

				if(this.user.password !== this.user.passwordConfirm) {
					Bus.emit('error', 'Passwords don\'t match');

					return;
				}

				Backend.post('auth/register', {user: this.user, classCode: this.classCode})
					.then(() => this.$router.push({name: 'Login'}))
					.then(() => Bus.emit('success', 'Registration complete!'))
					// eslint-disable-next-line no-console
					.catch(err => console.error(err.message));
			}
		}
	};
</script>

<style lang="scss" scoped>
	.view {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		height: 100%;

		.card {
			max-width: 400px;
			width: 100%;
		}

		form {
			overflow: hidden;
		}

		.button {
			float: right;
			clear: both;
		}
	}
</style>