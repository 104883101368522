<template>
	<BaseCard :title="currentClass.name">
		<div class="buttons">
			<BaseButton v-if="isTeacher" type="link" label="Download results" @click="downloadResults">
				<IconDownload :width="24" :height="24" :strokewidth="1" />
			</BaseButton>

			<BaseButton v-if="isTeacher" type="link" label="Show students" @click="showStudents">
				<IconUsers :width="24" :height="24" :strokewidth="1" />
			</BaseButton>
		</div>

		<p v-if="isTeacher" class="code">
			Code: <pre>{{ currentClass.code }}</pre>
		</p>

		<table>
			<thead>
				<tr>
					<th colspan="2">
						Courses
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="course in courses" :key="course.id">
					<td class="course-name">
						<div>{{ course.name }}</div>
						<small v-if="!isTeacher">{{ course.progress || 0 }}%</small>
					</td>
					<td class="course-actions">
						<BaseButton v-if="isTeacher" type="primary" :href="`/admin/submissions?courseId=${course.id}&classId=${currentClass.id}`">
							<span>Submissions</span>
							<IconArrowRight :width="16" :height="16" />
						</BaseButton>
						<BaseButton v-else type="primary" :href="'/course/' + course.id">
							<span v-if="course.sessionId">Continue</span>
							<span v-else>Start</span>
							<IconArrowRight :width="16" :height="16" />
						</BaseButton>
					</td>
				</tr>
			</tbody>
		</table>
	</BaseCard>
</template>

<script>
	import BaseButton from '../components/BaseButton.vue';
	import BaseCard from '../components/BaseCard.vue';
	import IconArrowRight from '../components/icons/IconArrowRight.vue';
	import {hasRole, isAdmin} from '../inc/auth';
	import IconUsers from './icons/IconUsers.vue';
	import ClassStudentsModal from './ClassStudentsModal.vue';
	import IconDownload from './icons/IconDownload.vue';
	import Backend from '../inc/backend';
	import {handleFileDownload} from '../inc/utils';

	export default {
		components: {
			BaseButton,
			BaseCard,
			IconArrowRight,
			IconUsers,
			IconDownload
		},
		props: {
			currentClass: {
				type: Object,
				required: true
			},
			userCourses: {
				type: Array,
				required: true
			}
		},
		computed: {
			isTeacher() {
				return isAdmin() || hasRole('Classes', this.currentClass.id, 'teacher');
			},
			courses() {
				return this.userCourses.filter(course => this.currentClass.courses.includes(course.id));
			}
		},
		methods: {
			showStudents() {
				this.$modal.show(ClassStudentsModal, {classId: this.currentClass.id}, {classes: 'class-students-modal', height: 'auto', scrollable: true});
			},
			downloadResults() {
				Backend.get(`/classes/${this.currentClass.id}/results`, {responseType: 'blob'})
					.then(handleFileDownload);
			}
		}
	};
</script>

<style lang="scss" scoped>
	p {
		margin-bottom: 1rem;
	}

	pre {
		display: inline;
	}

	table {
		margin-bottom: 0;
	}

	th, td {
		padding-left: 0;
		padding-right: 0;
		border: none;
	}

	.course-name {
		padding-right: $default_padding;
	}

	.course-actions {
		text-align: right;
	}

	.buttons {
		position: absolute;
		top: $default_padding;
		right: $default_padding;
	}
</style>