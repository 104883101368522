<template>
	<BaseCard :title="course.name" :classes="['course']">
		<p>{{ course.meta.shortDescription }}</p>
		<ProgressBar :width="course.progress" />
		<footer>
			<span>{{ footerText }}</span>
			<div v-if="course.timeEnded" class="buttons">
				<BaseButton v-if="canReset" type="secondary" @click="resetCourse">
					<IconCross :width="16" :height="16" /><span>Reset</span>
				</BaseButton>
				<BaseButton type="primary" :href="getReviewCourseUrl(course.id)">
					<span>View</span><IconArrowRight :width="16" :height="16" />
				</BaseButton>
			</div>
			<div v-else-if="!course.sessionId" class="buttons">
				<BaseButton type="primary" :href="getCourseUrl(course.id)">
					<span>Start</span><IconArrowRight :width="16" :height="16" />
				</BaseButton>
			</div>
			<div v-else class="buttons">
				<BaseButton v-if="canReset" type="secondary" @click="resetCourse">
					<IconCross :width="16" :height="16" /><span>Reset</span>
				</BaseButton>
				<BaseButton type="primary" :href="getCourseUrl(course.id)">
					<span>Continue</span><IconArrowRight :width="16" :height="16" />
				</BaseButton>
			</div>
		</footer>
	</BaseCard>
</template>

<script>
	import BaseCard from './BaseCard';
	import ProgressBar from './ProgressBar';
	import BaseButton from './BaseButton';
	import IconCross from './icons/IconCross';
	import IconArrowRight from './icons/IconArrowRight';
	import Backend from '../inc/backend';
	import ago from '../inc/ago';
	import {getSettings} from '../inc/courseUtils';
	import {isAdmin} from '../inc/auth';

	export default {
		components: {
			BaseCard,
			ProgressBar,
			BaseButton,
			IconCross,
			IconArrowRight
		},
		props: {
			course: {
				type: Object,
				required: true
			}
		},
		computed: {
			footerText() {
				return (this.course.timeStarted ? `Started ${ago(this.course.timeStarted)}` : 'Not started yet');
			},
			courseSettings() {
				return getSettings(this.course);
			},
			canReset() {
				return isAdmin() || !this.courseSettings.disableReset;
			}
		},
		methods: {
			getCourseUrl(id) {
				return `/course/${id}`;
			},
			getReviewCourseUrl(id) {
				return `/review-course/${id}`;
			},
			resetCourse() {
				this.$modal.show('dialog', {
					title: 'Reset course progress?',
					text: 'This will permanently reset your progress on this course. This action is irreversible. Do you want to continue?',
					buttons: [
						{
							title: 'Cancel',
							handler: () => {
								this.$modal.hide('dialog');
							}
						},
						{
							title: 'Reset',
							class: 'vue-dialog-button danger',
							handler: () => {
								Backend.delete(`sessions/${this.course.sessionId}`).then(() => {
									this.course.progress = 0;
									this.course.timeStarted = null;
									this.course.timeEnded = null;
									this.course.sessionId = null;
								});

								this.$modal.hide('dialog');
							}
						}
					]
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	@include breakpoint(small) {
		.buttons {
			margin-top: 10px;
		}
	}
</style>