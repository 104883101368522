<template>
	<div class="home">
		<h1>{{ state.reason }}</h1>

		<form @submit.prevent="submit">
			<span>{{ state.issuerData.iss }}/</span><input v-model="authorizationEndpoint" type="text" placeholder="Authorization endpoint" /><br />
			<span>{{ state.issuerData.iss }}/</span><input v-model="jwksUri" type="text" placeholder="JWKS URI" /><br />
			<button type="submit">
				Create issuer
			</button>
		</form>
	</div>
</template>

<script>
	import Backend from '../../../inc/backend';
	import Store from '../../../inc/store';

	export default {
		data() {
			return {
				authorizationEndpoint: '',
				jwksUri: ''
			};
		},

		computed: {
			state() {
				return parseJwt(this.$route.params.state);
			}
		},

		methods: {
			submit() {
				Backend.post('auth/lti/add-issuer', {
					authorizationEndpoint: this.authorizationEndpoint,
					jwksUri: this.jwksUri,
					state: this.$route.params.state
				}).then(res => {
					if(res.data.redirect) {
						document.location = res.data.redirect;
					}
					else {
						throw new Error(res.data.message || 'An error occured');
					}
				}).catch(err => {
					// eslint-disable-next-line no-alert
					alert(err.message);
				});
			}
		}
	};

	/**
	 * @param {String} token A JWT token
	 * @returns {Object} Returns a JWT's payload, ignoring any signature
	 */
	function parseJwt(token) {
		const base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

		return JSON.parse(jsonPayload);
	}
</script>