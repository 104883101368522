<template>
	<transition-group name="grow" tag="ol" class="notices">
		<li v-for="notice in notices" :key="notice.id" :class="[notice.type, 'notice']" v-html="notice.message" />
	</transition-group>
</template>

<script>
	import Bus from '../inc/bus';
	import Store from '../inc/store';

	export default {
		data() {
			return {
				notices: [],
				counter: 0
			};
		},

		created() {
			Bus.on('success', message => {
				this.addSuccess(message);
			});

			Bus.on('error', message => {
				this.addError(message);
			});

			Bus.on('clearNotices', () => {
				this.notices = [];
				this.counter = 0;
			});

			const params = new URLSearchParams(location.search);
			const error = params.get('error');

			if(error === 'invalid_lti_credentials') {
				this.addError(`A user with this email address already exists and therefore<br> your account couldn't be created. Please contact us at<br> <a href="mailto:${Store.tenant.email}">${Store.tenant.email}</a> for assistance.`);
			}
		},

		methods: {
			addSuccess(message) {
				return this.addNotice('success', message);
			},

			addError(message) {
				return this.addNotice('error', message);
			},

			addNotice(type, notice) {
				let message = notice;

				if(typeof notice === 'object' && typeof notice.message === 'string') {
					message = notice.message;
				}

				const msg = {
					id: this.counter++,
					type,
					message
				};

				this.notices.push(msg);

				setTimeout(() => {
					this.notices.splice(this.notices.indexOf(msg), 1);
				}, 3000);
			}
		}
	};
</script>

<style lang="scss">
	.notices {
		padding: 0 0 1em 0;

		.notice {
			color: $color__white;
			padding: 0.75em;
			margin-bottom: 1em;
			border-radius: $border_radius;

			a {
				color: $color__white;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.success {
			background: $color__green;
		}

		.info {
			background: $color__cyan;
		}

		.error {
			background: $color__red;
		}
	}
</style>