export default [
	{
		path: '/admin/submissions',
		name: 'AdminSubmissions',
		component: () => import('../../views/admin/Submissions.vue'),
		meta: {layout: 'admin'},
		props: route => ({
			courseId: route.query.courseId,
			classId: route.query.classId || ''
		}),
		beforeEnter: (to, from, next) => {
			if(to.query.courseId) {
				next();
			}
			else {
				next(new Error('Missing required query parameters.'));
			}
		}
	},
	{
		path: '/admin/submissions/groups',
		name: 'AdminSubmissionGroups',
		component: () => import('../../views/admin/SubmissionGroups.vue'),
		meta: {layout: 'admin'},
		props: route => ({
			sessionId: route.query.sessionId
		}),
		beforeEnter: (to, from, next) => {
			if(to.query.sessionId) {
				next();
			}
			else {
				next(new Error('Missing required query parameters.'));
			}
		}
	},
	{
		path: '/admin/submissions/grade',
		name: 'AdminGradeSubmission',
		component: () => import('../../views/admin/GradeSubmission.vue'),
		meta: {layout: 'admin'},
		props: route => ({
			sessionId: route.query.sessionId,
			courseId: route.query.courseId,
			groupId: route.query.groupId
		}),
		beforeEnter: (to, from, next) => {
			const {sessionId, courseId, groupId} = to.query;

			if(sessionId && courseId && groupId) {
				next();
			}
			else {
				next(new Error('Missing required query parameters.'));
			}
		}
	}
];