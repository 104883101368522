export default [
	{
		path: '/admin/courses',
		name: 'AdminCourses',
		component: () => import('../../views/admin/Courses.vue'),
		meta: {layout: 'admin'}
	},
	{
		path: '/admin/courses/create',
		name: 'AdminCoursesCreate',
		component: () => import('../../views/admin/CoursesCreate.vue'),
		meta: {layout: 'admin'}
	}
];