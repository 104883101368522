<template>
	<div class="modal-inner">
		<IconCross :width="16" :height="16" class="close" @click.native="$modal.hideAll()" />
		<h2>Registered students</h2>
		<div class="modal-content">
			<ul v-if="students.length">
				<li v-for="student in students" :key="student.id">
					{{ student.firstName }} {{ student.lastName }}
				</li>
			</ul>
			<p v-else>
				No students yet.
			</p>
		</div>
	</div>
</template>

<script>
	import IconCross from './icons/IconCross';
	import Backend from '../inc/backend';

	export default {
		name: 'ClassStudentsModal',
		components: {
			IconCross
		},
		props: {
			classId: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				students: []
			};
		},
		created() {
			Backend.get('users', {params: {class: this.classId, role: 'student'}})
				.then(res => {
					this.students = res.data;
				});
		}
	};
</script>

<style lang="scss" scoped>
	.modal-content {
		margin-bottom: $default_padding;
	}

	li {
		margin-bottom: 10px;
	}
</style>