<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><polygon fill="none" stroke-miterlimit="10" points="16,17 2,10 16,3 30,10 " :stroke="fill" /> <path fill="none" stroke-miterlimit="10" d="M6,12v11c0,2.761,4.477,5,10,5 c5.523,0,10-2.239,10-5V12" stroke-linecap="butt" :stroke="fill" /> <line fill="none" stroke-miterlimit="10" x1="30" y1="14" x2="30" y2="21" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>