<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><path d="M22,10V7a6.018,6.018,0,0,0-6-6h0a6.018,6.018,0,0,0-6,6v3" fill="none" stroke-miterlimit="10" /><rect x="4" y="14" width="24" height="17" rx="3" fill="none" stroke-miterlimit="10" :stroke="fill" /><line x1="16" y1="24" x2="16" y2="27" fill="none" stroke-miterlimit="10" /><circle cx="16" cy="21" r="3" fill="none" stroke-miterlimit="10" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>