/* eslint-disable consistent-return */
/* eslint-disable no-cond-assign */
/* eslint-disable prefer-reflect */
/* eslint-disable require-jsdoc */

import Backend from './backend';
import {getDefaultSettings} from './groupSettings';
import Store from './store';
import blockDefs from '../inc/blockDefinitions';
import {v4} from 'uuid';
import Bus from './bus';
import {after, middle} from '@webbmaffian/rank';

const cache = {};
const indices = new WeakMap();
const list = [];

export function clearCache() {
	list.splice(0, list.length);

	for(const key in cache) {
		if(cache.hasOwnProperty(key)) {
			delete cache[key];
		}
	}
}

export function getGroup(id) {
	if(cache[id]) {
		return cache[id];
	}

	return false;
}

export function getAncestors(id) {
	const ancestors = [id];
	let group = id;

	while(cache[group] && cache[group].parentId) {
		group = cache[group].parentId;
		ancestors.push(group);
	}

	return ancestors;
}

export function hasAncestor(childId, ancestorId) {
	let child = childId;
	const ancestor = ancestorId;

	if(!child || !ancestor) {
		return false;
	}

	while(child !== ancestor) {
		if(!cache[child] || !cache[child].parentId) {
			return false;
		}

		child = cache[child].parentId;
	}

	return true;
}

export function getNext(id, direction = 1, mustHaveBlocks = false) {
	if(!cache[id]) {
		return;
	}

	const index = indices.get(cache[id]);
	const newIndex = index + direction;

	if(list[newIndex]) {
		if(mustHaveBlocks && !list[newIndex].numBlocks) {
			return getNext(list[newIndex].id, direction, mustHaveBlocks);
		}

		return list[newIndex].id;
	}
}

export function setCache(course) {
	if(typeof course !== 'object' || !course.id) {
		throw new Error('Invalid input.');
	}

	clearCache();

	cache[course.id] = course;
	indices.set(course, list.length);
	list.push(course);

	if(course.children && course.children.length) {
		setCacheRecursively(course.children, course.id);
	}
}

export function getSettings(course) {
	const ancestors = getAncestors(course.id).map(id => getGroup(id));
	const settings = {};

	ancestors.unshift(course);

	for(const ancestor of ancestors) {
		for(const key in ancestor.meta) {
			if(ancestor.meta[key] === 'inherit' || settings.hasOwnProperty(key)) {
				continue;
			}

			settings[key] = {
				course: ancestor,
				value: ancestor.meta[key]
			};
		}
	}

	if(!Object.keys(settings).length) {
		return getDefaultSettings(true);
	}

	return settings;
}

export function deleteGroup(parentId, id) {
	const promise = _deleteGroup(parentId, id, Store.course.children);

	if(promise) {
		return promise;
	}

	return Promise.reject(new Error('Course group not found.'));
}

// eslint-disable-next-line no-underscore-dangle
function _deleteGroup(parentId, id, courses) {
	if(!Array.isArray(courses) || !courses.length) {
		return Promise.reject(new Error('Invalid input.'));
	}

	// eslint-disable-next-line guard-for-in
	for(const index in courses) {
		if(courses[index].id === id) {
			return Backend.delete(`courses/${Store.course.id}/groups/${parentId}/${id}`).then(() => {
				courses.splice(index, 1);
				setCache(Store.course);
			});
		}

		if(courses[index].children && courses[index].children.length) {
			const promise = _deleteGroup(parentId, id, courses[index].children);

			if(promise) {
				return promise;
			}
		}
	}

	return false;
}

export function updateGroup(id, data) {
	const promise = _updateGroup(id, data, Store.course.children);

	if(promise) {
		return promise;
	}

	return Promise.reject(new Error('Course group not found.'));
}

// eslint-disable-next-line no-underscore-dangle
function _updateGroup(id, data, courses) {
	if(!Array.isArray(courses) || !courses.length || !Object.keys(data).length) {
		return Promise.reject(new Error('Invalid input.'));
	}

	// eslint-disable-next-line guard-for-in
	for(const index in courses) {
		if(courses[index].id === id) {
			return Backend.put(`courses/${Store.course.id}/groups/${id}`, data).then(() => {
				for(const key in data) {
					if(typeof courses[index][key] !== 'undefined') {
						courses[index][key] = data[key];
					}
				}
				setCache(Store.course);
			});
		}

		if(courses[index].children && courses[index].children.length) {
			const promise = _updateGroup(id, data, courses[index].children);

			if(promise) {
				return promise;
			}
		}
	}

	return false;
}

export function updateProgress(group) {
	return Backend.get(`sessions/${Store.session.id}/groups/${group}/progress`).then(res => {
		Store.groupAnswers = res.data.answers;
		Store.courseGroupProgress = res.data.progress;
		Store.courseProgress = res.data.courseProgress;
	});
}

export function fetchGroupBlocks(groupId, courseId) {
	const group = groupId || Store.courseGroup.id;
	const course = courseId || Store.course.id;

	return Backend.get(`courses/${course}/groups/${group}/blocks`).then(res => {
		Store.groupBlocks = res.data;
		Store.courseGroup.blocks = res.data.map(block => block.id);
	});
}

export function isCourseComplete() {
	return Boolean(Store.courseGroupProgress && Store.courseGroupProgress.timeDone);
}

export function showCourseFeedback(type) {
	const groupSettings = getSettings(Store.courseGroup);
	const blockDef = blockDefs.find(b => b.type === type);

	return (blockDef.category === 'question') && groupSettings.showFeedback && (groupSettings.showFeedback.value === 'yes') && isCourseComplete();
}

export function cloneBlock(block, courseId, groupId) {
	if(groupId === Store.courseGroup.id) {
		throw new Error('Cannot clone a block within the same group.');
	}

	return Backend.get(`courses/${courseId}/groups/${groupId}/blocks`).then(res => {
		const exists = res.data.some(b => b.id === block.id);

		if(exists) {
			return Promise.resolve();
		}

		const lastBlock = res.data[res.data.length - 1];
		const clone = {
			id: block.id,
			cloned: true,
			sortOrder: lastBlock ? after(lastBlock.sortOrder) : middle()
		};

		return Backend.put(`courses/${courseId}/groups/${groupId}/blocks/${block.id}`, clone);
	});
}

export function copyBlock(oldBlock, courseId, groupId) {
	const block = {
		type: oldBlock.type,
		content: oldBlock.content,
		meta: oldBlock.meta,
		groupId,
		options: {}
	};

	if(oldBlock.options) {
		// eslint-disable-next-line guard-for-in
		for(const og in oldBlock.options) {
			const options = Object.values(oldBlock.options[og]).map(option => ({
				id: v4(),
				isCorrect: option.isCorrect,
				label: option.label
			}));
			const newOptionGroup = v4();

			if(block.type === 'opencloze') {
				block.content.content = updateOpenClozeContent(block.content.content, og, newOptionGroup);
			}

			block.options[newOptionGroup] = options;
		}
	}

	return Backend.post(`courses/${courseId}/groups/${groupId}/blocks`, block);
}

export function saveBlock(block, config) {
	return Backend.put(`courses/${Store.course.id}/groups/${Store.courseGroup.id}/blocks/${block.id}`, block, config)
		.then(() => Bus.emit('blocksaved', block.id));
}

function updateOpenClozeContent(content, oldGroup, newGroup) {
	for(const child of content) {
		if(child.type === 'options' && child.attrs['data-id'] === oldGroup) {
			child.attrs['data-id'] = newGroup;
		}
		else if(child.content) {
			child.content = updateOpenClozeContent(child.content, oldGroup, newGroup);
		}
	}

	return content;
}

function setCacheRecursively(children, parentId) {
	for(const child of children) {
		child.parentId = parentId;
		cache[child.id] = child;
		indices.set(child, list.length);
		list.push(child);

		if(child.children && child.children.length) {
			setCacheRecursively(child.children, child.id);
		}
	}
}