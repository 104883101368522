<template>
	<div class="progress-bar">
		<div :style="currentWidth">
			<span v-if="showText" :style="textPosition">{{ displayText }}</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			width: {
				type: Number,
				default: 0
			},
			text: {
				type: String,
				default: ''
			}
		},
		computed: {
			currentWidth() {
				return `width: ${this.width}%;`;
			},
			showText() {
				return this.width > 0 && this.width <= 100;
			},
			textPosition() {
				return this.width < 10 ? 'left: calc(100% + 5px);' : 'right: 5px;';
			},
			displayText() {
				return this.text || `${this.width}%`;
			}
		}
	};
</script>

<style lang="scss">
	.progress-bar {
		display: flex;
		width: calc(100% + #{$default_padding * 2});
		background-color: $color__semi_dark;
		height: 3px;
		font-size: 0.75rem;
		margin: 1.75em -#{$default_padding} 0;

		& > div {
			position: relative;
			color: $color__light_blue;
			justify-content: flex-end;
			text-align: right;
			white-space: nowrap;
			background-color: $color__light_blue;
			transition: width .3s ease;

			span {
				position: absolute;
				top: -1.5em;
			}
		}
	}
</style>