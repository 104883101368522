<template>
	<div class="edit-controls" :class="classes">
		<template v-if="adding">
			<BaseButton type="secondary" @click="toggleAdding">
				<IconCross :width="16" :height="16" />
			</BaseButton>
			<input ref="groupName" v-model="groupName" type="text" placeholder="Group name" @keyup.enter="create" />
			<BaseButton type="secondary" @click="create">
				<IconCheck :width="16" :height="16" />
			</BaseButton>
		</template>
		<template v-else>
			<BaseButton type="secondary" @click="toggleAdding">
				<IconAdd :width="16" :height="16" />
				<span>Add group</span>
			</BaseButton>
		</template>
	</div>
</template>

<script>
	import BaseButton from './BaseButton';
	import IconAdd from './icons/IconAdd';
	import IconCheck from './icons/IconCheck';
	import IconCross from './icons/IconCross';
	import Backend from '../inc/backend';
	import Bus from '../inc/bus';
	import Store from '../inc/store';
	import {after, middle} from '@webbmaffian/rank';

	export default {
		components: {
			BaseButton,
			IconAdd,
			IconCheck,
			IconCross
		},
		data() {
			return {
				adding: false,
				groupName: ''
			};
		},
		computed: {
			classes() {
				if(this.adding) {
					return 'adding';
				}

				return '';
			},
			hasCourses() {
				return Store.course.children.length > 1;
			}
		},
		watch: {
			adding(adding) {
				if(adding) {
					this.$nextTick(() => {
						this.$refs.groupName.focus();
					});
				}
			}
		},
		methods: {
			toggleAdding() {
				this.adding = !this.adding;
			},
			create() {
				if(!this.groupName.replace(' ', '').length) {
					return;
				}

				const group = {
					name: this.groupName,
					sortOrder: Store.course.children && Store.course.children.length ? after(Store.course.children[Store.course.children.length - 1].sortOrder) : middle()
				};

				Backend.post(`/courses/${Store.course.id}/groups`, group)
					.then(res => {
						Object.assign(group, res.data);

						Store.course.children.push(group);

						this.groupName = '';
						Bus.emit('success', 'Group created');
						this.$emit('updated');
					})
					.catch(() => {
						Bus.emit('error', 'Could not create group');
					});
			}
		}
	};
</script>

<style lang="scss">
	.edit-controls {
		padding: 0 $sidebar__padding;
		display: flex;
		flex-flow: row nowrap;
		margin: 0 -4px;

		.button.secondary {
			padding: 0 2px;

			a, button {
				background-color: rgba($color: $color__white, $alpha: 0.3);
				color: $color__dark;
				transition: background-color .1s ease-out;

				&:hover {
					background-color: rgba($color: $color__white, $alpha: 0.5);
				}
			}
		}

		&.adding, &.updating {
			button {
				height: 100%;
			}
		}

		input {
			margin: 0 10px;
			color: $color__text;
		}
	}
</style>