<template>
	<aside :class="classes">
		<header class="hide-small">
			<router-link :to="{ name: 'Course', params: { courseId } }" tag="h1">
				{{ course.name }}
			</router-link>

			<span v-if="editMode && course._own" class="settings" @click.stop="showSettings">
				<IconGear :width="28" :height="28" />
			</span>

			<ProgressBar :width="currentProgess" />
		</header>

		<span class="menu-button" role="button" aria-label="Toggle menu" @click="toggleMenu">
			<IconMenu :width="32" :stroke="2" />
		</span>

		<nav :class="navClasses">
			<component :is="courseTreeComponent" v-model="course.children" @change="$emit('change')" />
		</nav>

		<EditCourseControls v-if="editMode" class="hide-small" @updated="reloadMenu" />

		<footer>
			<BaseButton v-if="!isPublic" type="secondary" class="back-link hide-small" href="/">
				<span>Back to courses</span>
			</BaseButton>

			<BaseButton type="secondary" @click="previousGroup">
				<IconArrowLeft :width="24" :height="20" />
			</BaseButton>

			<BaseButton type="secondary" @click="nextGroup">
				<IconArrowRight :width="24" :height="20" />
			</BaseButton>
		</footer>
	</aside>
</template>

<script>
	import Store from '../inc/store';
	import BaseButton from './BaseButton';
	import IconArrowLeft from './icons/IconArrowLeft';
	import IconArrowRight from './icons/IconArrowRight';
	import IconGear from './icons/IconGear';
	import IconMenu from './icons/IconMenu';
	import EditCourseControls from './EditCourseControls';
	import CourseTree from './CourseTree';
	import EditorCourseTree from './EditorCourseTree';
	import Bus from '../inc/bus';
	import {getNext, setCache} from '../inc/courseUtils';
	import ProgressBar from './ProgressBar';
	import sidebarMixin from '../mixins/sidebarMixin';

	export default {
		components: {
			BaseButton,
			IconArrowLeft,
			IconArrowRight,
			IconGear,
			IconMenu,
			EditCourseControls,
			CourseTree,
			EditorCourseTree,
			ProgressBar
		},
		mixins: [sidebarMixin],
		computed: {
			course() {
				return Store.course;
			},
			courseId() {
				return Store.course.id || 0;
			},
			classes() {
				return 'sidebar course-sidebar' + (this.showMenu ? ' active' : '');
			},
			navClasses() {
				return 'sidebar-content hide-small' + (Store.courseMenuDragging ? ' dragging' : '');
			},
			groupId() {
				return Store.courseGroup.id;
			},
			courseTreeComponent() {
				return (this.editMode ? 'EditorCourseTree' : CourseTree);
			},
			currentProgess() {
				return this.course ? this.course.progress : 0;
			},
			editMode() {
				return Store.editMode;
			},
			isPublic() {
				return Store.user.isPublic;
			}
		},
		methods: {
			reloadMenu() {
				setCache(this.course);
			},
			startDragging() {
				Store.courseMenuDragging = true;
			},
			endDragging() {
				Store.courseMenuDragging = false;
			},
			showSettings() {
				Bus.emit('openCourseSettings', this.course.id);
			},
			nextGroup() {
				const groupId = getNext(this.groupId, 1, true);

				if(groupId) {
					this.$router.push({name: 'CourseGroup', params: {courseId: this.course.id, groupId}});
				}
			},
			previousGroup() {
				const groupId = getNext(this.groupId, -1, true);

				if(groupId) {
					if(groupId === this.courseId) {
						this.$router.push({name: 'Course', params: {courseId: groupId}});
					}
					else {
						this.$router.push({name: 'CourseGroup', params: {courseId: this.course.id, groupId}});
					}
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	$_course_sidebar_item_height: $font__size * 2;
	$_course_sidebar_border_color: rgba(
		$color: $color__white,
		$alpha: 0.3,
	);

	.course-sidebar {
		user-select: none;
	}

	h1 {
		cursor: pointer;
	}

	header {
		padding: $sidebar__padding $sidebar__padding * 2;
		position: relative;
	}

	.progress-bar::v-deep {
		position: absolute;
		bottom: 0;
		left: 0;
		margin: 1.75em 0 0;
		width: 100%;
		background-color: $color__semi_white;

		& > div {
			color: $color__semi_white2;
			background-color: $color__white;

			span {
				top: -2em;
				font-size: 0.85rem;
			}
		}
	}

	.settings {
		position: absolute;
		left: $default_padding * 2;
		color: $color__semi_white2;
		cursor: pointer;

		&:hover {
			color: $color__white;
		}

		svg {
			display: block;
		}
	}
</style>