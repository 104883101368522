export default [
	{
		path: '/admin/affiliates',
		name: 'AdminAffiliates',
		component: () => import('../../views/admin/TenantAffiliates.vue'),
		meta: {layout: 'admin'}
	},
	{
		path: '/admin/affiliates/add',
		name: 'AdminAffiliateAdd',
		component: () => import('../../views/admin/TenantAffiliateEdit.vue'),
		meta: {layout: 'admin'}
	},
	{
		path: '/admin/affiliates/:id',
		name: 'AdminAffiliateEdit',
		component: () => import('../../views/admin/TenantAffiliateEdit.vue'),
		meta: {layout: 'admin'}
	}
];