<template>
	<article :class="classes">
		<div class="action move">
			<IconMoveVertical :width="28" :height="28" />
		</div>
		<div v-if="blockDef.settings.length" class="action settings" @click="showSettings">
			<IconGear :width="24" :height="24" />
		</div>
		<component :is="blockDef.editorComponent" ref="block" :block.sync="block" v-on="$listeners">
			<slot />
		</component>
		<div class="action more">
			<IconDots :width="24" :height="24" @click.native.stop="showDropdown" />
			<ul :class="optionsClasses">
				<li @click.stop="showRemoveBlockModal">
					Remove
				</li>
				<li @click.stop="showCloneBlockModal">
					Clone
				</li>
				<li v-if="block.cloned" @click.stop="showDetachBlockModal">
					Detach
				</li>
			</ul>
		</div>
		<div class="block-type">
			<IconClone v-if="block.cloned" :width="10" :height="10" />
			{{ blockDef.label }}
		</div>
	</article>
</template>

<script>
	import IconMoveVertical from './icons/IconMoveVertical';
	import IconTrash from './icons/IconTrash';
	import IconGear from './icons/IconGear';
	import IconDots from './icons/IconDots';
	import IconClone from './icons/IconClone';
	import blockDefs, {loadEditorBlocks} from '../inc/blockDefinitions';
	import SettingsModalBlock from './SettingsModalBlock';
	import CloneBlockModal from './CloneBlockModal';
	import DetachBlockModal from './DetachBlockModal';
	import RemoveBlockModal from './RemoveBlockModal';
	import Bus from '../inc/bus';

	export default {
		components: {
			...loadEditorBlocks(),
			IconMoveVertical,
			IconTrash,
			IconGear,
			IconDots,
			IconClone
		},
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				dropdownOpen: false
			};
		},
		computed: {
			classes() {
				return [
					'block',
					this.block.type,
					(this.block.meta.style || 'regular') + '-' + this.block.type,
					this.blockDef.category
				];
			},
			blockDef() {
				return blockDefs.find(b => b.type === this.block.type);
			},
			optionsClasses() {
				return 'actions-dropdown' + (this.dropdownOpen ? ' open' : '');
			}
		},
		created() {
			if(typeof this.block.meta !== 'object' || Array.isArray(this.block.meta)) {
				this.$set(this.block, 'meta', {});
			}

			// Add non-existing meta to the block, to ensure reactivity
			for(const metaKey in this.blockDef.meta) {
				if(this.block.meta.hasOwnProperty(metaKey)) {
					continue;
				}

				if(typeof this.blockDef.meta[metaKey] === 'object') {
					this.$set(this.block.meta, metaKey, JSON.parse(JSON.stringify(this.blockDef.meta[metaKey])));
				}
				else {
					this.$set(this.block.meta, metaKey, this.blockDef.meta[metaKey]);
				}
			}

			Bus.on('clickAnywhere', () => {
				this.dropdownOpen = false;
			});
		},
		methods: {
			showCloneBlockModal() {
				this.dropdownOpen = false;
				this.$modal.show(CloneBlockModal, {block: this.block}, {classes: 'clone-modal', height: 'auto'});
			},
			showDetachBlockModal() {
				this.dropdownOpen = false;
				this.$modal.show(DetachBlockModal, {block: this.block}, {classes: 'detach-modal', height: 'auto'});
			},
			showRemoveBlockModal() {
				this.dropdownOpen = false;
				this.$modal.show(RemoveBlockModal, {block: this.block}, {classes: 'remove-modal', height: 'auto'});
			},
			showSettings() {
				this.$modal.show(SettingsModalBlock, {settings: this.blockDef.settings, block: this.block}, {classes: 'settings-modal', height: 'auto'});
			},
			showDropdown() {
				this.dropdownOpen = !this.dropdownOpen;
			}
		}
	};
</script>

<style lang="scss" scoped>
	article {
		position: relative;
		background: $color__background;
		outline: 1px dashed $border_color;
		min-height: $site__padding * 4;

		& > .block-inner {
			padding: $site__padding $site__padding * 2;
		}

		&.image {
			min-height: $site__padding * 5;
		}
	}

	.action {
		position: absolute;
		left: 0;
		width: 80px;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: rgba($color__dark, 0.5);
		transition: 200ms;
		z-index: 10;

		&:hover {
			color: $color__dark;
		}

		&.move {
			top: 0;
			cursor: grab;

			&:active {
				cursor: grabbing;
			}
		}

		&.settings {
			bottom: 0;
			cursor: pointer;
		}

		&.more {
			cursor: pointer;
			top: 0;
			right: 0;
			left: unset;

			svg {
				transform: rotate(90deg);
			}

			.actions-dropdown {
				list-style-type: none;
				background-color: $color__white;
				border-radius: $border_radius;
				box-shadow: $box_shadow__input;
				padding: 10px;
				margin: 0;
				position: absolute;
				top: 75%;
				left: 0;
				transform: translateX(-30%);
				min-width: 100px;
				text-align: center;

				li {
					color: rgba($color__dark, 0.5);
					transition: 200ms;
					line-height: 1.5em;

					&:hover {
						color: $color__dark;
					}
				}

				&:not(.open) {
					display: none;
				}
			}
		}
	}

	.block-type {
		position: absolute;
		bottom: 5px;
		right: 10px;
		text-align: center;
		text-transform: uppercase;
		font-size: 0.75em;
		opacity: 0.5;
		cursor: inherit;

		svg {
			margin-right: 10px;
		}
	}
</style>