<template>
	<label :class="classes">
		<span class="input">
			<input :type="type" :name="name" value="1" :checked="checked" @input="$emit('input', $event.target.checked)" />
			<IconCheck :width="20" :height="20" />
		</span>
		<span v-if="label">{{ label }}</span>
	</label>
</template>

<script>
	import IconCheck from './icons/IconCheck';

	export default {
		components: {
			IconCheck
		},
		props: {
			name: {
				type: String,
				default: ''
			},
			label: {
				type: String,
				required: false
			},
			radio: [
				Boolean,
				Number
			],
			checked: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			type() {
				return this.radio ? 'radio' : 'checkbox';
			},
			classes() {
				return `bool-input ${this.type}`;
			}
		}
	};
</script>

<style lang="scss">
	.bool-input {
		flex: 0 0 20px;
		display: inline-flex;
		flex-flow: row nowrap;
		margin-right: 10px;
		margin-bottom: 10px;
		line-height: 1.25em;

		.input {
			position: relative;
			display: inline-block;
			background-color: $color__white;
			box-shadow: $box_shadow;
			height: 20px;
			width: 20px;
			margin-right: 5px;
			flex: 0 0 20px;

			input {
				position: absolute;
				visibility: hidden;
			}
		}

		&.checkbox .input {
			border-radius: $border_radius;
		}

		&.radio .input {
			border-radius: 50%;
		}

		svg {
			display: none;
		}

		input:checked + svg {
			position: absolute;
			top: -2px;
			left: 4px;
			display: block;
			color: $color__light-blue;
		}
	}
</style>