<template>
	<div class="modal-inner">
		<IconCross :width="16" :height="16" class="close" @click.native="done" />
		<h2>{{ title }}</h2>
		<div class="modal-content">
			<div v-for="(setting, index) in settings" :key="index">
				<label v-if="setting.type !== 'checkbox'">{{ setting.label }}</label>
				<v-select v-if="setting.type === 'select'" v-model="block.meta[setting.metaKey]" :options="setting.options" :reduce="setting.reduce" label="label" :clearable="false" :searchable="false" :multiple="setting.multiple" />
				<input v-else-if="setting.type === 'text'" v-model="block.meta[setting.metaKey]" type="text" :placeholder="setting.placeholder" @blur="setting.onblur(block)" />
				<input v-else-if="setting.type === 'number'" v-model="block.meta[setting.metaKey]" type="number" @blur="setting.onblur(block)" />
				<BoolInput v-else-if="setting.type === 'checkbox'" :checked="block.meta[setting.metaKey]" :label="setting.label" @input="checked => block.meta[setting.metaKey] = checked" />
				<div v-else-if="setting.type === 'range'" class="range">
					<span class="min">{{ setting.min }}</span>
					<input v-model="block.meta[setting.metaKey]" type="range" :min="setting.min" :max="setting.max" />
					<span class="max">{{ setting.max }}</span>
					<span class="current">{{ block.meta[setting.metaKey] }}</span>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<BaseButton type="secondary" @click="reset">
				Reset
			</BaseButton>
			<BaseButton type="primary" @click="done">
				Done
			</BaseButton>
		</div>
	</div>
</template>

<script>
	import IconCross from './icons/IconCross';
	import BaseButton from './BaseButton';
	import BoolInput from './BoolInput';
	import Store from '../inc/store';

	export default {
		name: 'SettingsModalBlock',
		components: {
			IconCross,
			BaseButton,
			BoolInput
		},
		props: {
			settings: Array,
			block: Object
		},
		data() {
			return {
				originalData: ''
			};
		},
		computed: {
			title() {
				return this.block.type.charAt(0).toUpperCase() + this.block.type.slice(1) + ' block settings';
			}
		},
		created() {
			this.originalData = JSON.stringify(this.block);
		},
		methods: {
			done() {
				Store.hasUnsavedChanges = true;
				this.$modal.hideAll();
			},
			reset() {
				Object.assign(this.block, JSON.parse(this.originalData));
				this.$modal.hideAll();
			}
		}
	};
</script>