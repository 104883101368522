<template>
	<div>
		<header class="site-header">
			<BrandLogo />
			<AccountMenu />
		</header>
		<slot />
	</div>
</template>

<script>
	import BrandLogo from '../components/BrandLogo';
	import AccountMenu from '../components/AccountMenu';

	export default {
		components: {
			BrandLogo,
			AccountMenu
		}
	};
</script>

<style lang="scss">
	.course-layout {
		padding: $site__padding $site__padding / 2;
	}

	@include breakpoint-min(small) {
		.course-layout {
			padding: $site__padding;
		}
	}
</style>