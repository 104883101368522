<template>
	<div class="block-area-wrapper">
		<transition-group name="page" tag="section" class="block-area">
			<template>
				<BaseBlock v-for="(block, i) in blocks" :key="block.id" :block="block" :next-block="blocks[i + 1]" />
			</template>
		</transition-group>
	</div>
</template>

<script>
	import Store from '../inc/store';
	import BaseBlock from './BaseBlock';

	export default {
		components: {
			BaseBlock
		},
		computed: {
			blocks() {
				return Store.groupBlocks;
			},
			groupId() {
				return Store.courseGroup.id;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.block-area-wrapper {
		display: flex;
		flex-flow: column nowrap;
		flex: 1;
	}

	.block-area {
		counter-reset: question;
	}

	.sidebar.active + .content {
		.timed-quiz-notice {
			left: 66%;
		}
	}
</style>