<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" :width="width" :height="height"><g stroke-width="1" :fill="_secondaryfill" :stroke="_secondaryfill"><line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="13.5" y1="2.5" x2="2.5" y2="13.5" :stroke="fill" /> <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="2.5" y1="2.5" x2="13.5" y2="13.5" :stroke="fill" /> </g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>