<template>
	<div>
		<AccountMenu />
		<slot />
	</div>
</template>

<script>
	import AccountMenu from '../components/AccountMenu';

	export default {
		components: {
			AccountMenu
		}
	};
</script>

<style lang="scss">
	.review-layout {
		.view > {
			main {
				margin-left: $sidebar__width;
				transition: margin-left 0.2s ease;
			}

			@include breakpoint-min('large') {
				.sidebar.active + main {
					margin-left: $sidebar__width_active;
				}
			}
		}
	}
</style>