<template>
	<div class="block-area-wrapper">
		<transition-group v-if="showBlocks" name="page" tag="section" class="block-area">
			<template>
				<BaseBlock v-for="(block, i) in blocks" :key="block.id" :block="block" :next-block="blocks[i + 1]" />
			</template>
		</transition-group>
		<div v-else-if="timeLimit && hideBlocks" class="timed-quiz-notice">
			<h2>Timed test</h2>
			<p>This is a timed test. You have <strong>{{ timeLimit }} minutes</strong> to complete it. You cannot take this test again after it has been started.</p>

			<BaseButton type="primary" @click="startQuiz">
				Start test
			</BaseButton>
		</div>
		<div v-else-if="timeLimit" class="timed-quiz-notice">
			<h2>Test completed</h2>
			<p>You have completed this test.</p>

			<BaseButton v-if="canResetQuiz" type="primary" @click="resetQuiz">
				Reset test
			</BaseButton>
		</div>
		<QuizTimer v-if="timeLimit && showTimer" :start="quizStartTime" :end="quizEndTime" @ended="endQuiz" />
	</div>
</template>

<script>
	import {hasRole, isAdmin} from '../inc/auth';
	import Backend from '../inc/backend';
	import {fetchGroupBlocks, getSettings, updateProgress} from '../inc/courseUtils';
	import Store from '../inc/store';
	import BaseBlock from './BaseBlock';
	import BaseButton from './BaseButton';
	import QuizTimer from './QuizTimer';
	import {DateTime} from 'luxon';

	const QuizStates = Object.freeze({
		noQuiz: 0,
		active: 1,
		inactive: 2,
		ended: 3
	});

	export default {
		components: {
			BaseBlock,
			BaseButton,
			QuizTimer
		},
		data() {
			return {
				quizState: QuizStates.inactive,
				quizStartTime: 0,
				quizEndTime: 0
			};
		},
		computed: {
			blocks() {
				return Store.groupBlocks;
			},
			settings() {
				return Store.courseGroup ? getSettings(Store.courseGroup) : {};
			},
			groupId() {
				return Store.courseGroup.id;
			},
			timeLimit() {
				if(this.settings.timeLimit) {
					return parseInt(this.settings.timeLimit.value, 10);
				}

				return 0;
			},
			progress() {
				return Store.courseGroupProgress;
			},
			showBlocks() {
				return this.quizState === QuizStates.noQuiz || this.quizState === QuizStates.active;
			},
			hideBlocks() {
				return this.quizState === QuizStates.inactive;
			},
			canResetQuiz() {
				return isAdmin() || hasRole('Courses', Store.course.id, 'teacher');
			},
			showTimer() {
				return this.quizState === QuizStates.active;
			}
		},
		watch: {
			progress(progress) {
				this.setQuizState(progress);
			}
		},
		methods: {
			startQuiz() {
				Backend.post(`sessions/${Store.session.id}/groups/${this.groupId}/start-timer`)
					.then(() => updateProgress(this.groupId, true))
					.then(() => fetchGroupBlocks())
					.then(() => this.setQuizState(this.progress));
			},
			resetQuiz() {
				Backend.delete(`sessions/${Store.session.id}/groups/${this.groupId}/reset-timer`)
					.then(() => updateProgress(this.groupId))
					.then(() => fetchGroupBlocks())
					.then(() => this.setQuizState(this.progress));
			},
			endQuiz() {
				Backend.put(`sessions/${Store.session.id}/groups/${this.groupId}/complete`)
					.then(() => updateProgress(this.groupId))
					.then(() => this.setQuizState(this.progress));
			},
			setQuizState(progress) {
				if(!this.timeLimit) {
					this.quizState = QuizStates.noQuiz;
					Store.isQuiz = false;

					return;
				}

				if(progress.timeDone) {
					this.quizState = QuizStates.ended;
				}
				else if(progress.timeStarted) {
					this.quizStartTime = progress.timeStarted;
					this.quizEndTime = DateTime.fromMillis(progress.timeStarted, {zone: 'utc'}).plus({minutes: this.timeLimit}).toMillis();
					const now = DateTime.utc().toMillis();

					if(this.quizEndTime <= now) {
						this.quizState = QuizStates.ended;

						return;
					}

					if(this.quizState !== QuizStates.active) {
						fetchGroupBlocks().then(() => {
							this.quizState = QuizStates.active;
							Store.isQuiz = true;
						});
					}
				}
				else if(this.quizState !== QuizStates.inactive) {
					this.quizState = QuizStates.inactive;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.block-area-wrapper {
		display: flex;
		flex-flow: column nowrap;
		flex: 1;
	}

	.quiz-timer::v-deep {
		margin-top: auto;

		.progress-bar {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}

	.block-area {
		counter-reset: question;
	}

	.timed-quiz-notice::v-deep {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		transition: left 0.2s ease;
		padding: $default_padding * 2;
		margin: 0 $default_padding;
		border-radius: $border_radius;
		background-color: $color__white;
		box-shadow: $box_shadow;
		max-width: 480px;
		width: 100%;

		p {
			line-height: 2em;
		}
	}

	.sidebar.active + .content {
		.timed-quiz-notice {
			left: 66%;
		}
	}
</style>