import axios from 'axios';
import Backend from './backend';
import Bus from './bus';
import {slug} from './text';
import {v4} from 'uuid';
import promiseRetry from 'promise-retry';
import Store from './store';

const http = axios.create({
	withCredentials: false,
	timeout: 5000
});

http.interceptors.response.use(response => response, error => {
	Bus.emit('error', error.toString(), error, 'axios');

	throw error;
});

const ObjectStore = {
	upload: file => {
		const ext = file.name.split('.').pop();
		const filename = v4() + '.' + ext;

		const sendFile = retry => Backend.get('upload-url?file=' + filename)
			.then(res => {
				const name = slug(file.name);

				const config = {
					headers: {
						'Content-Type': file.type,
						'Content-Disposition': `inline; filename="${name}"`
					}
				};

				return http.put(res.data, file, config);
			})
			.then(() => ({
				name: file.name,
				filename,
				type: file.type
			}))
			.catch(retry);

		return promiseRetry(sendFile, {retries: 4, minTimeout: 500});
	},
	getFileUrl: file => {
		const params = new URLSearchParams();

		Object.keys(file).forEach(key => params.append(key, encodeURIComponent(file[key])));

		if(Store.course && Store.course.id) {
			params.append('course', Store.course.id);
		}

		return Backend.get('file-url?' + params.toString()).then(res => res.data);
	}
};

export default ObjectStore;