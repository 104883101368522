<template>
	<div class="quiz-timer">
		<ProgressBar :width="percent" :text="timerText" />
	</div>
</template>

<script>
	import ProgressBar from './ProgressBar';
	import {DateTime} from 'luxon';

	export default {
		components: {
			ProgressBar
		},
		props: {
			start: {
				type: Number,
				required: true
			},
			end: {
				type: Number,
				required: true
			}
		},
		data() {
			return {
				duration: null,
				startDiff: 0
			};
		},
		computed: {
			almostDone() {
				return this.percent > 75;
			},
			percent() {
				if(!this.duration) {
					return 0;
				}

				if(this.duration.toMillis() < 1000) {
					return 100;
				}

				const percent = (this.startDiff.toMillis() - this.duration.toMillis()) / this.startDiff.toMillis();

				return Math.floor(percent * 1000) / 10;
			},
			timerText() {
				if(!this.duration) {
					return '';
				}

				const mins = this.duration.minutes;
				const secs = Math.floor(this.duration.seconds);

				return (mins > 0 ? mins + ' min, ' : '') + secs + ' sec';
			}
		},
		mounted() {
			this.setupTimer();
		},
		methods: {
			setupTimer() {
				const start = DateTime.fromMillis(this.start);
				const end = DateTime.fromMillis(this.end);

				this.startDiff = end.diff(start);

				if(this.startDiff.toMillis() <= 1000) {
					return;
				}

				const timer = setInterval(() => {
					this.duration = end.diffNow([
						'minutes',
						'seconds'
					]);

					if(this.duration.toMillis() < 1000) {
						clearInterval(timer);

						this.$emit('ended');
					}
				}, 100);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.quiz-timer::v-deep {
		.progress-bar {
			& > div span {
				top: 1em;

				@include breakpoint('small') {
					top: 0.5em;
				}
			}
		}
	}
</style>