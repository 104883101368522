import axios from 'axios';
import Bus from './bus';

const Backend = axios.create({
	baseURL: '/api/v1',
	withCredentials: false,
	timeout: 10000
});

// Ensure we have data for POST and PUT requests, otherwise Axios doesn't
// set the content type to application/json.
Backend.interceptors.request.use(req => {
	// eslint-disable-next-line array-element-newline
	if(['post', 'POST', 'put', 'PUT'].includes(req.method) && !req.data) {
		req.data = {};
	}

	return req;
});

Backend.interceptors.response.use(response => response, error => {
	if(error.response && error.response.status === 503) {
		return;
	}

	Bus.emit('error', {
		message: (typeof error.response.data === 'object' ? error.response.data.error : error.message),
		code: error.response.status,
		type: 'axios'
	});

	throw error;
});

export default Backend;