<template>
	<draggable class="block-area" :list="blocks" v-bind="dragOptions" @change="sorted" @start="startDragging" @end="endDragging">
		<transition-group :name="transitionGroup">
			<component :is="getBlockComponent(block)" v-for="block in blocks" :key="block.id" :block="block" @edit="edited" />
		</transition-group>
	</draggable>
</template>

<script>
	/* eslint-disable no-underscore-dangle, no-multi-assign */
	import draggable from 'vuedraggable';
	import Store from '../inc/store';
	import BaseEditorBlock from './BaseEditorBlock';
	import BaseBlock from './BaseBlock';
	import {after, before, between, middle} from '@webbmaffian/rank';
	import {v4} from 'uuid';

	export default {
		components: {
			draggable,
			BaseEditorBlock,
			BaseBlock
		},
		data() {
			return {
				abortController: null
			};
		},
		computed: {
			dragging() {
				return Store.blockDragging;
			},
			cloning() {
				return Store.blockCloning;
			},
			editMode() {
				return Store.editMode;
			},
			blocks() {
				return Store.groupBlocks;
			},
			transitionGroup() {
				return this.dragging ? 'dragging-block' : 'block';
			},
			groupId() {
				return Store.courseGroup.id;
			},
			dragOptions() {
				return {
					animation: 200,
					group: {
						name: 'blocks',
						put: ['blocks'],
						pull: to => (to.options.dataCourse === this.groupId ? 'clone' : true)
					},
					disabled: false,
					direction: 'vertical',
					handle: '.move',
					tag: 'section',
					class: {
						'block-area': true,
						editing: this.editMode,
						dragging: this.dragging
					},
					swapThreshold: 0.25
				};
			}
		},
		methods: {
			getBlockComponent(block) {
				return (block._own === false ? BaseBlock : BaseEditorBlock);
			},
			sorted(e) {
				Store.hasUnsavedChanges = true;

				if(e.added) {
					const {element, newIndex} = e.added;

					element._own = true;
					element.id = element.id || v4();
					element.sortOrder = this.getSortOrder(newIndex);

					this.$set(Store.courseGroup, 'numBlocks', Store.groupBlocks.length);
				}
				else if(e.moved) {
					const {element, newIndex} = e.moved;
					const newSortOrder = this.getSortOrder(newIndex);

					element.sortOrder = newSortOrder;
				}
			},
			edited() {
				Store.hasUnsavedChanges = true;
			},
			startDragging(e) {
				Store.blockDragging = {
					index: e.oldIndex,
					block: this.blocks[e.oldIndex],
					blocks: this.blocks
				};
			},
			endDragging() {
				setTimeout(() => {
					Store.blockDragging = false;
				});
			},
			getSortOrder(index) {
				if(Store.groupBlocks.length === 1) {
					return middle();
				}
				if(index === 0) {
					return before(Store.groupBlocks[1].sortOrder);
				}
				if(index === Store.groupBlocks.length - 1) {
					return after(Store.groupBlocks[index - 1].sortOrder);
				}

				return between(Store.groupBlocks[index - 1].sortOrder, Store.groupBlocks[index + 1].sortOrder);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.block-area::v-deep {
		flex-grow: 1;
		display: flex;
		flex-flow: column;
		padding-top: $site__padding/2;
		user-select: none;

		& > span {
			flex-grow: 1;

			&:empty {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: $site__padding;
				margin: 0 $site__padding * 2;
				border: 2px dashed $border_color;

				&::before {
					content: 'Drop a block here';
				}
			}
		}

		.legend.sortable-ghost {
			position: relative;
			z-index: 1;
			border: 10px solid transparent;
			border-left-color: $color__link;
			border-right-color: $color__link;
			height: 22px;
			margin: -11px 0;
			padding: 0;
			overflow: hidden;
			transition: transform 0s !important;

			&::before {
				content: '';
				display: block;
				width: 100%;
				height: 2px;
				background: $color__link;
			}
		}

		.block {
			&.sortable-ghost {
				background: rgba($color__dark, 0.05);
			}
		}

		.sortable-drag {
			cursor: grabbing;
		}

		.question:not(.sortable-ghost)::before {
			display: none;
		}
	}
</style>