<template>
	<div v-if="loggedIn" :class="{'account-menu': true, open}" tabindex="-1" @focusout="closeMenu" @mouseleave="closeMenu">
		<IconUserCircle :width="40" :height="40" @click.native="openMenu" @mouseenter.native="openMenu" />
		<nav>
			<ul>
				<li class="head">
					<span>Signed in as <strong>{{ userName }}</strong></span>
				</li>
				<li v-if="!isPublic">
					<router-link :to="{name: 'MyCourses'}">
						My courses
						<IconCourses :width="28" :height="28" />
					</router-link>
				</li>
				<li v-if="!isPublic">
					<router-link :to="{name: 'MyClasses'}">
						My classes
						<IconStudentHat :width="28" :height="28" />
					</router-link>
				</li>
				<li v-if="canEdit">
					<router-link :to="{name: 'Admin'}">
						Administration
						<IconTie :width="28" :height="28" />
					</router-link>
				</li>
				<li>
					<a @click="logout">
						Sign out
						<IconPowerButton :width="28" :height="28" />
					</a>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
	import Store from '../inc/store';
	import Backend from '../inc/backend';
	import IconUserCircle from './icons/IconUserCircle';
	import IconCourses from './icons/IconCourses';
	import IconStudentHat from './icons/IconStudentHat';
	import IconPowerButton from './icons/IconPowerButton';
	import IconTie from './icons/IconTie';
	import Bus from '../inc/bus';
	import {isAdmin, isTeacher} from '../inc/auth';

	export default {
		components: {
			IconUserCircle,
			IconCourses,
			IconStudentHat,
			IconPowerButton,
			IconTie
		},
		data() {
			return {
				userName: Store.user.firstName,
				loggedIn: Store.loggedIn,
				open: false
			};
		},
		computed: {
			course() {
				return Store.course;
			},
			editMode() {
				return Store.editMode;
			},
			canEdit() {
				return isAdmin() || isTeacher();
			},
			isPublic() {
				return Store.user.isPublic;
			}
		},
		methods: {
			logout() {
				Backend.post('auth/logout', {}).then(result => {
					Store.loggedIn = result.data.loggedIn;

					this.$router.push({name: 'Login'});
				});
			},
			openMenu() {
				this.open = true;
				Bus.emit('openedAccountMenu');
			},
			closeMenu() {
				this.open = false;
			},
			editCourse() {
				Store.editMode = true;
				this.closeMenu();
			},
			previewCourse() {
				Store.editMode = false;
				Bus.emit('menuUpdated');
				this.closeMenu();
			}
		}
	};
</script>

<style lang="scss">
	.account-menu {
		position: fixed;
		top: $default_padding * 2 - 10px;
		right: $default_padding * 3 - 10px;
		text-align: right;
		z-index: 200;
		user-select: none;

		&:focus {
			outline: none;
		}

		&.open nav {
			right: 0;
			opacity: 1;
			transition: right 0s, opacity .2s ease-out;
		}

		& > svg {
			position: absolute;
			top: 10px;
			right: 10px;
			z-index: 110;
			background-color: $color__white;
			border-radius: 50%;
		}

		nav {
			position: absolute;
			right: -9999px;
			opacity: 0;
			transition: opacity .2s ease-out, right 0s linear .2s;
			border-radius: $border_radius;
			background-color: $color__white;
			box-shadow: $box_shadow;
			z-index: 100;
		}

		ul {
			list-style: none;
			text-align: left;
			padding-left: 0;
		}

		li {
			display: block;

			&.head {
				padding-right: 65px;
				white-space: pre;
			}

			& > * {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: center;
				padding: 15px;
				line-height: 28px;
			}

			& > span {
				cursor: default;
			}

			& > a {
				cursor: pointer;
				color: inherit;
				text-decoration: inherit;

				&:hover {
					background-color: $color__hover;
				}
			}

			&:not(:last-child) {
				border-bottom: $border;
			}
		}
	}

	@include breakpoint(small) {
		.account-menu {
			right: $site__padding / 2;
		}
	}
</style>