<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" stroke-miterlimit="10" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><polyline fill="none" points=" 2,16 2,22 22,22 22,16 " :stroke="fill" /> <line fill="none" x1="12" y1="1" x2="12" y2="16" stroke-linecap="butt" /> <polyline fill="none" points="6,10 12,16 18,10 " /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 2
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>