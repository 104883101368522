<template>
	<div class="modal-inner">
		<IconCross :width="16" :height="16" class="close" @click.native="$modal.hideAll()" />
		<h2>Remove block</h2>
		<div class="modal-content">
			<p>Are you sure you want to remove this block?</p>
		</div>
		<div class="modal-footer">
			<BaseButton type="secondary" @click="$modal.hideAll()">
				Cancel
			</BaseButton>
			<BaseButton type="primary" @click="removeBlock">
				Remove
			</BaseButton>
		</div>
	</div>
</template>

<script>
	import IconCross from './icons/IconCross';
	import BaseButton from './BaseButton';
	import Backend from '../inc/backend';
	import Bus from '../inc/bus';
	import {fetchGroupBlocks} from '../inc/courseUtils';
	import Store from '../inc/store';

	export default {
		name: 'RemoveBlockModal',
		components: {
			IconCross,
			BaseButton
		},
		props: {
			block: Object
		},
		computed: {
			courseId() {
				return Store.course.id;
			},
			groupId() {
				return Store.courseGroup.id;
			}
		},
		methods: {
			removeBlock() {
				return Backend.delete(`courses/${this.courseId}/groups/${this.groupId}/blocks/${this.block.id}`)
					.then(() => fetchGroupBlocks())
					.then(() => this.$modal.hideAll())
					.catch(err => Bus.emit('error', err.message));
			}
		}
	};
</script>

<style lang="scss" scoped>
	.modal-content {
		margin-bottom: $default_padding;
	}
</style>