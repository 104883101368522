/* eslint-disable require-jsdoc */
export const groupSettings = [
	{
		label: 'Show feedback when user completes lesson',
		type: 'select',
		key: 'showFeedback',
		options: [
			{label: 'Inherit', code: 'inherit'},
			{label: 'Yes', code: 'yes'},
			{label: 'No', code: 'no'}
		],
		reduce: opt => opt.code,
		multiple: false,
		default: 'inherit'
	},
	{
		label: 'Time limit (minutes)',
		type: 'number',
		key: 'timeLimit',
		onblur: () => null,
		default: 0,
		childOnly: true
	},
	{
		label: 'Hide automatic question numbers',
		type: 'checkbox',
		key: 'hideNumbers',
		childOnly: true,
		hideLabel: true
	},
	{
		label: 'Hide from students',
		type: 'select',
		key: 'hidden',
		options: [
			{label: 'Inherit', code: 'inherit'},
			{label: 'No', code: 'no'},
			{label: 'Yes', code: 'yes'}
		],
		reduce: opt => opt.code,
		multiple: false,
		default: 'inherit',
		allowOverride: true
	},
	{
		label: 'Time to complete (minutes)',
		type: 'number',
		key: 'timeToComplete',
		rootOnly: true,
		default: 60,
		onblur: () => null
	},
	{
		label: 'CEFR scores',
		type: 'button',
		key: 'cefr',
		action: 'editCEFR',
		hideLabel: true
	},
	{
		label: 'Disable course reset',
		type: 'checkbox',
		key: 'disableReset',
		rootOnly: true,
		hideLabel: true
	}
];

export function getDefaultSettings(isRoot = false) {
	const defaults = {};

	for(const setting of groupSettings) {
		defaults[setting.key] = setting.default;

		if(isRoot) {
			if(defaults[setting.key] === 'inherit' && setting.options) {
				defaults[setting.key] = setting.options.find(opt => opt.code !== 'inherit').code;
			}
		}
	}

	return defaults;
}