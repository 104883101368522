<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><path d="M19.549,23.586l-4.925-1.408a1,1,0,0,1-.716-.82l-.415-2.905A6,6,0,0,0,17,13V10.252A6.166,6.166,0,0,0,11.185,4,6,6,0,0,0,5,10v3a6,6,0,0,0,3.507,5.453l-.415,2.905a1,1,0,0,1-.716.82L2.451,23.586A2,2,0,0,0,1,25.509V29H21V25.509A2,2,0,0,0,19.549,23.586Z" fill="none" stroke-miterlimit="10" :stroke="fill" /> <path d="M25,29h6V23.562a2,2,0,0,0-1.515-1.941l-5.833-1.458a1,1,0,0,1-.747-.829l-.412-2.881A6,6,0,0,0,26,11V8.252A6.166,6.166,0,0,0,20.185,2,5.977,5.977,0,0,0,17,2.8" fill="none" stroke-miterlimit="10" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>