export default [
	{
		path: '/admin/users',
		name: 'AdminUsers',
		component: () => import('../../views/admin/Users.vue'),
		meta: {layout: 'admin'}
	},
	{
		path: '/admin/users/create',
		name: 'AdminUsersCreate',
		component: () => import('../../views/admin/UsersEdit.vue'),
		meta: {layout: 'admin'}
	},
	{
		path: '/admin/users/:id',
		name: 'AdminUsersEdit',
		component: () => import('../../views/admin/UsersEdit.vue'),
		meta: {layout: 'admin'}
	}
];