<template>
	<div class="home view">
		<div class="courses">
			<div class="active">
				<h2>My courses</h2>
				<CourseCard
					v-for="course in activeCourses"
					:key="course.id"
					:course="course"
				/>

				<p v-if="!activeCourses.length">
					You do not have any active courses.
				</p>
			</div>

			<div class="completed">
				<h2>Completed courses</h2>
				<CourseCard
					v-for="course in completedCourses"
					:key="course.id"
					:course="course"
				/>

				<p v-if="!completedCourses.length">
					You do not have any completed courses.
				</p>
			</div>
		</div>

		<NoticeArea />
	</div>
</template>

<script>
	import Store from '../inc/store';
	import Backend from '../inc/backend';
	import CourseCard from '../components/CourseCard.vue';
	import NoticeArea from '../components/NoticeArea';

	export default {
		components: {
			CourseCard,
			NoticeArea
		},
		data() {
			return {
				activeCourses: [],
				completedCourses: []
			};
		},
		computed: {
			user() {
				return Store.user;
			}
		},
		created() {
			Backend.get('courses?sort=activity').then(res => {
				this.activeCourses = res.data.filter(c => !c.timeEnded);
				this.completedCourses = res.data.filter(c => c.timeEnded);
			});
		}
	};
</script>

<style lang="scss">
	.home.view {
		& > .notices {
			position: absolute;
			bottom: 60px;
			left: 60px;
			list-style-type: none;
		}

		@include breakpoint-min(medium) {
			.courses {
				display: flex;
				flex-flow: row wrap;
				max-width: 1360px;
				margin: 0 auto;

				.active,
				.completed {
					padding-left: $default_padding * 2;
					padding-right: $default_padding * 2;
					flex: 0 1 calc(50%);
				}
			}
		}
	}
</style>