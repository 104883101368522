/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable no-process-env */
import Vue from 'vue';
import App from './App.vue';
import Router from './inc/router';
import NoticeArea from './components/NoticeArea.vue';
import VModal from 'vue-js-modal';
import vSelect from 'vue-select';
import Bus from './inc/bus';

Vue.component('NoticeArea', NoticeArea);
Vue.component('v-select', vSelect);

Vue.use(VModal, {dialog: true, adaptive: true, focusTrap: true});

Vue.config.errorHandler = (err, vm) => {
	// eslint-disable-next-line no-undef
	if(process.env.NODE_ENV === 'development' || process.env.ENVIRONMENT === 'dev') {
		console.error(err);
	}

	Bus.emit('error', err.message, err, vm);
};

new Vue({
	router: Router,
	render: h => h(App)
}).$mount('#app');