<template>
	<div class="login view">
		<NoticeArea />

		<BaseCard title="Choose a new password">
			<form method="post" @submit.prevent="recoverPassword">
				<input ref="password" v-model="password" type="password" placeholder="New password" /><br />
				<input v-model="password2" type="password" placeholder="Repeat password" /><br />
				<BaseButton type="submit">
					Save & sign in
				</BaseButton>
			</form>
		</BaseCard>
	</div>
</template>

<script>
	import Backend from '../inc/backend';
	import Store from '../inc/store';
	import Router from '../inc/router';
	import BaseCard from '../components/BaseCard.vue';
	import BaseButton from '../components/BaseButton.vue';
	import Bus from '../inc/bus';

	export default {
		components: {
			BaseCard,
			BaseButton
		},
		data() {
			return {
				password: '',
				password2: ''
			};
		},
		mounted() {
			this.$refs.password.focus();
		},
		methods: {
			recoverPassword() {
				Backend.post('auth/recover-password', {
					token: this.$route.params.token,
					password: this.password,
					password2: this.password2
				}).then(() => {
					Store.loaded = false;
					Router.push({path: this.$route.query.redirect || '/'});
				}).catch(err => {
					Bus.emit('error', err);
				});
			}
		}
	};
</script>