import Vue from 'vue';

const Store = Vue.observable({
	loaded: false,
	loggedIn: false,
	user: {},
	tenant: {},
	course: {},
	courseProgress: {},
	session: {},
	courseGroup: {},
	courseGroupIndices: {},
	courseGroupProgress: {},
	courseBreadcrumbs: [],
	courseMenuDragging: false,
	blockDragging: false,
	blockCloning: false,
	groupBlocks: [],
	groupAnswers: [],
	editMode: false,
	isQuiz: false,
	hasUnsavedChanges: false
});

export default Store;