<template>
	<li
		:class="classes"
		:draggable="draggable"
	>
		<router-link :to="{ name: 'CourseGroup', params: { courseId: course.id, groupId: node.id } }" tag="span" class="content">
			<span class="text">
				{{ node.name }}
			</span>
			<span v-if="editMode && node._own !== false" class="handle" @mousedown="draggable = true" @mouseup="draggable = false">
				<IconMoveVertical :width="15" :height="15" />
			</span>
			<span v-if="editMode" class="settings" @click.stop="showSettings">
				<IconGear :width="15" :height="15" />
			</span>
		</router-link>

		<transition name="collapse">
			<ol v-if="active">
				<EditorCourseTreeNode v-for="child in node.children" :key="child.id" :node="child" :list="node.children" />
			</ol>
		</transition>
	</li>
</template>

<script>
	import Store from '../inc/store';
	import IconMoveVertical from './icons/IconMoveVertical';
	import IconGear from './icons/IconGear';
	import {setDomNode} from './EditorCourseTree';
	import Bus from '../inc/bus';

	export default {
		name: 'EditorCourseTreeNode',
		components: {
			IconMoveVertical,
			IconGear
		},
		props: {
			node: {
				type: Object,
				required: true
			},
			list: {
				type: Array,
				required: true
			}
		},

		data() {
			return {
				draggable: false,
				dragging: false,
				hover: false
			};
		},

		computed: {
			course() {
				return Store.course;
			},
			editMode() {
				return Store.editMode;
			},
			classes() {
				return [
					this.hover ? ('insert-' + this.hover) : false,
					this.draggable ? 'draggable' : false,
					this.dragging ? 'dragging' : false,
					this.blockDragging ? 'block-dragging' : false
				];
			},
			active() {
				return Store.courseBreadcrumbs.includes(this.node.id);
			},
			blockDragging() {
				return Store.blockDragging;
			}
		},

		created() {
			this.$on('mouseover', position => {
				this.hover = position;
			});

			this.$on('mouseout', () => {
				this.hover = false;
				this.draggable = false;
			});

			this.$on('dragging', dragging => {
				this.dragging = dragging;
			});
		},

		mounted() {
			setDomNode(this.$el, this.node, this.list, this);
		},

		methods: {
			showSettings() {
				Bus.emit('openCourseSettings', this.node.id);
			}
		}
	};
</script>

<style lang="scss" scoped>
	$row_height: $font__size * 2;
	$border_color: rgba($color__white, 0.3);
	$active_background_color: rgba($color__white, 0.1);

	li {
		display: flex;
		flex-wrap: wrap;
		line-height: $row_height;
		color: $color__semi_white2;

		&.dragging {
			opacity: 0.25;
		}

		&.draggable::v-deep {
			.router-link-exact-active::after {
				content: none;
			}
		}
	}

	.content {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		line-height: $row_height;
		position: relative;
		width: 100px; /* No idea why this works, but otherwise long texts will get broken. */

		.text {
			flex: 1 0 1em;
		}

		svg {
			align-self: center;
			justify-self: center;
		}
	}

	ol ol {
		width: 100%;
		list-style: none;
		padding-left: $site__padding/4;

		ol {
			padding-left: $site__padding/2;
		}

		li {
			padding-left: $site__padding/4;
			border-left: 1px solid $border_color;

			&::before {
				content: '';
				position: relative;
				height: $row_height/2;
				width: $site__padding/4;
				color: $color__dark;
				border-bottom: 1px solid $border_color;
				display: inline-block;
				left: -$site__padding/4;
			}

			&:last-child {
				border-left: none;

				&::before {
					border-left: 1px solid $border_color;
				}
			}
		}
	}

	.text {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.active {
		color: $color__white;
	}

	.highlight:not(.draggable-ghost) {
		border-top-width: $row_height/2;
		border-bottom-width: $row_height/2;
	}

	.handle {
		width: $row_height;
		text-align: center;
		cursor: grab;

		&:active {
			cursor: grabbing;
		}
	}

	.settings {
		width: $row_height;
		text-align: center;
	}

	.router-link-exact-active {
		& > span {
			color: #fff;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			z-index: -1;
			pointer-events: none;
			top: 0;
			right: -40px;
			width: $sidebar__width_active;
			border-top: $row_height/2 solid $active_background_color;
			border-bottom: $row_height/2 solid $active_background_color;
		}
	}

	.insert-before > .content::before,
	.insert-after > .content::before {
		content: '';
		display: block;
		position: absolute;
		right: -40px;
		width: $sidebar__width_active;
		border-top: 4px solid $active_background_color;
		pointer-events: none;
	}

	.insert-before > .content::before {
		top: -2px;
	}

	.insert-inside > .content {
		background: $active_background_color;
	}

	.insert-after > .content::before {
		bottom: -2px;
	}
</style>