export default [
	{
		path: '/admin/classes',
		name: 'AdminClasses',
		component: () => import('../../views/admin/Classes.vue'),
		meta: {layout: 'admin'}
	},
	{
		path: '/admin/classes/create',
		name: 'AdminClassesCreate',
		component: () => import('../../views/admin/ClassesEdit.vue'),
		meta: {layout: 'admin'}
	},
	{
		path: '/admin/classes/:id',
		name: 'AdminClassesEdit',
		component: () => import('../../views/admin/ClassesEdit.vue'),
		meta: {layout: 'admin'}
	}
];