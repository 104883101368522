<template>
	<div :class="classes">
		<CourseSidebar @change="changedSidebar" />

		<main class="content">
			<EditorBlockArea v-if="editMode" />
			<BlockArea v-else />

			<NoticeArea />

			<div class="content-footer">
				<BrandLogo v-if="!editMode" />

				<nav v-if="!editMode" class="right">
					<BaseButton v-if="showPrev" type="secondary" @click="previousGroup">
						<IconArrowLeft :width="16" :height="16" />
						<span>Previous</span>
					</BaseButton>
					<BaseButton v-if="showNext" type="secondary" @click="nextGroup">
						<span>Next</span>
						<IconArrowRight :width="16" :height="16" />
					</BaseButton>
					<BaseButton v-else type="primary" @click="completeCourse">
						<span>Complete course</span>
					</BaseButton>
					<BaseButton v-if="isStarted" type="primary" @click="completeGroup">
						<span>Submit</span>
					</BaseButton>
				</nav>
				<nav v-else>
					<BaseButton type="primary" class="saved-blocks-button" @click="saveBlocks">
						<span>Save blocks</span>
					</BaseButton>
				</nav>
			</div>
		</main>

		<BlockLegend v-if="editMode" class="block-legend" />
	</div>
</template>

<script>
	import Backend from '../inc/backend';
	import Store from '../inc/store';
	import CourseSidebar from '../components/CourseSidebar';
	import BlockArea from '../components/BlockArea';
	import EditorBlockArea from '../components/EditorBlockArea';
	import BlockLegend from '../components/BlockLegend';
	import BrandLogo from '../components/BrandLogo';
	import BaseButton from '../components/BaseButton';
	import IconArrowLeft from '../components/icons/IconArrowLeft';
	import IconArrowRight from '../components/icons/IconArrowRight';
	import NoticeArea from '../components/NoticeArea';
	import {getNext, getSettings, updateProgress} from '../inc/courseUtils';
	import Bus from '../inc/bus';
	import courseMixin from '../mixins/courseMixin';

	export default {
		name: 'SingleCourse',
		components: {
			CourseSidebar,
			BlockArea,
			EditorBlockArea,
			BlockLegend,
			BrandLogo,
			BaseButton,
			IconArrowLeft,
			IconArrowRight,
			NoticeArea
		},
		mixins: [courseMixin],
		computed: {
			editMode() {
				return Store.editMode;
			},
			showNext() {
				return this.course && this.groupId && !!getNext(this.groupId, 1, true);
			},
			showPrev() {
				return this.course && this.groupId && !!getNext(this.groupId, -1, true);
			},
			progress() {
				return Store.courseGroupProgress;
			},
			isComplete() {
				return this.progress && this.progress.timeDone;
			},
			isStarted() {
				return this.progress && this.progress.timeStarted && !this.progress.timeDone;
			},
			hideNumbers() {
				return this.groupSettings.hideNumbers && this.groupSettings.hideNumbers.value;
			},
			groupSettings() {
				return getSettings(this.group);
			},
			showFeedback() {
				if(!this.isComplete) {
					return false;
				}

				if(!this.groupSettings.showFeedback) {
					return false;
				}

				return this.groupSettings.showFeedback.value === 'yes';
			},
			classes() {
				return {
					'single-course': true,
					'view': true,
					'completed': this.isComplete,
					'show-feedback': this.showFeedback,
					'hide-numbers': this.hideNumbers,
					'is-quiz': Store.isQuiz
				};
			}
		},
		watch: {
			editMode() {
				this.loadGroupData(this.group.id);
			}
		},
		methods: {
			nextGroup() {
				const groupId = getNext(this.groupId, 1, true);

				if(groupId) {
					this.$router.push({name: 'CourseGroup', params: {courseId: this.course.id, groupId}});
				}
			},
			previousGroup() {
				const groupId = getNext(this.groupId, -1, true);

				if(groupId) {
					if(groupId === this.courseId) {
						this.$router.push({name: 'Course', params: {courseId: groupId}});
					}
					else {
						this.$router.push({name: 'CourseGroup', params: {courseId: this.course.id, groupId}});
					}
				}
			},
			completeCourse() {
				Backend.put(`sessions/${Store.session.id}/complete`)
					.then(() => updateProgress(this.groupId))
					.then(() => this.$router.push({name: 'ReviewCourse', params: {courseId: Store.course.id}}))
					.catch(() => Bus.emit('error', 'Could not submit complete course.'));
			},
			completeGroup() {
				Backend.put(`sessions/${Store.session.id}/groups/${this.groupId}/complete`)
					.then(() => updateProgress(this.groupId))
					.catch(() => Bus.emit('error', 'Could not submit answers for review.'));
			},
			saveBlocks() {
				Backend.put(`courses/${Store.course.id}/groups/${Store.courseGroup.id}/blocks`, Store.groupBlocks)
					.then(() => {
						Store.groupBlocks.forEach(block => Bus.emit('blocksaved', block.id));
						Store.hasUnsavedChanges = false;
						Bus.emit('success', 'Blocks saved successfully!');
					})
					.catch(err => {
						// eslint-disable-next-line no-console
						console.error(err);
						Bus.emit('error', 'An error occurred while saving.');
					});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.single-course {
		flex: 1;
		display: flex;
		flex-flow: column;

		&.show-feedback::v-deep {
			.block.question {
				.block-inner {
					padding-bottom: $site__padding / 2;
				}
			}
		}

		&.hide-numbers::v-deep {
			article.question header::before {
				display: none;
			}
		}
	}

	main::v-deep {
		flex: 1;
		display: flex;
		flex-flow: column;
		padding: $site__padding 0 $site__padding;

		p,
		ol,
		ul {
			margin-bottom: $line__height;

			&:last-child {
				margin-bottom: 0;
			}
		}

		table p {
			margin-bottom: 0;
		}

		@include breakpoint('small') {
			padding: $site__padding 0 0;

			.content-footer .secondary.button {
				display: none;
			}
		}
	}

	.notices {
		position: fixed;
		left: calc(#{$sidebar__width_active} + #{$site__padding});
		bottom: $site__padding;
		z-index: 100;
	}

	.content-footer {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		border-top: $border;
		padding: $site__padding $site__padding * 2 0;

		.logo-wrapper {
			padding: 0;

			@include breakpoint('small') {
				display: none;
			}
		}

		@include breakpoint('small') {
			padding: $site__padding / 2 $site__padding / 2;
		}
	}

	.single-course.is-quiz::v-deep {
		.quiz-timer {
			position: fixed;
			width: calc(100% - var(--sidebar-width));
			left: var(--sidebar-width);
			bottom: $site__padding * 3 + 1;
			z-index: 1;

			@include breakpoint('small') {
				bottom: $site__padding * 2 + 1;
			}
		}

		.content-footer {
			position: fixed;
			width: calc(100% - var(--sidebar-width));
			left: var(--sidebar-width);
			bottom: 0;
			background: $color__white;
			padding-bottom: $site__padding;

			@include breakpoint('small') {
				padding: $site__padding / 2 $site__padding / 2;
			}
		}

		main {
			padding-bottom: $site__padding * 3;
		}

		.sidebar.active + main {
			.content-footer, .quiz-timer {
				width: calc(100% - var(--sidebar-width-active));
				left: var(--sidebar-width-active);
			}
		}
	}

	nav {
		display: flex;
		flex-flow: row nowrap;
		margin-left: auto;

		.saved-blocks-button {
			margin-right: $default_padding * 1.5;
		}

		.button::v-deep {
			margin-left: 10px;
		}
	}

</style>