<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" :width="width" :height="height"><g :fill="_secondaryfill"><path d="M32,1C14.906,1,1,14.907,1,32s13.906,31,31,31s31-13.907,31-31S49.094,1,32,1z M20,28 c0-6.617,5.383-12,12-12s12,5.383,12,12v3c0,6.617-5.383,12-12,12s-12-5.383-12-12V28z M32,61c-6.701,0-12.873-2.291-17.789-6.122 C17.082,49.99,22.26,47,28,47h8c5.733,0,10.911,2.99,13.788,7.879C44.872,58.71,38.7,61,32,61z" :fill="fill" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>