<template>
	<div class="home view">
		<div class="header">
			<h2>My classes</h2>
			<BaseButton type="outlined" @click="joinClass">
				<IconAdd :width="12" :height="12" :strokewidth="3" />
				<span>Join</span>
			</BaseButton>
		</div>

		<div class="classes">
			<ClassCard v-for="c in classes" :key="c.id" :current-class="c" :user-courses="userCourses" />

			<p v-if="!classes.length">
				You do not have any active classes.
			</p>
		</div>

		<NoticeArea />
	</div>
</template>

<script>
	import Store from '../inc/store';
	import Backend from '../inc/backend';
	import NoticeArea from '../components/NoticeArea';
	import ClassCard from '../components/ClassCard.vue';
	import BaseButton from '../components/BaseButton.vue';
	import IconAdd from '../components/icons/IconAdd.vue';
	import ClassCodeModal from '../components/ClassCodeModal.vue';

	export default {
		components: {
			NoticeArea,
			ClassCard,
			BaseButton,
			IconAdd
		},
		data() {
			return {
				classes: [],
				userCourses: []
			};
		},
		computed: {
			user() {
				return Store.user;
			}
		},
		created() {
			this.updateClasses();
		},
		methods: {
			joinClass() {
				this.$modal.show(ClassCodeModal, {
					handleJoin: code => {
						if(typeof code !== 'string' || !code.match(/^[A-Z0-9]{8}$/u)) {
							return Promise.reject(new Error('Incorrect class code.'));
						}

						return Backend.post('/classes/join', {code}).then(() => this.updateClasses());
					}
				}, {height: 'auto'});
			},
			updateClasses() {
				return Promise.all([
					Backend.get('courses').then(res => {
						this.userCourses = res.data;
					}),
					Backend.get('classes').then(res => {
						this.classes = res.data;
					})
				]);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.home.view {
		width: 100%;
		max-width: 1360px;
		margin: 0 auto;

		& > .notices {
			position: absolute;
			bottom: 60px;
			left: 60px;
			list-style-type: none;
			z-index: 1000;
		}
	}

	.header::v-deep {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		column-gap: $default_padding;
		margin-bottom: $default_padding;

		button {
			line-height: 2em;
			padding: 0 12px;
		}

		h2 {
			margin-bottom: 0;
		}

		& > * {
			flex: 0 1 auto;
		}
	}

	@include breakpoint-min(medium) {
		.classes {
			column-count: 2;
			column-gap: 0;
			margin: $default_padding / -1;
		}

		.card::v-deep {
			break-inside: avoid;
			display: inline-block;
			width: 100%;
			margin-bottom: 0;
			padding: $default_padding;
		}
	}
</style>