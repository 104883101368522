export default [
	{
		path: '/admin/integrations',
		name: 'AdminIntegrations',
		component: () => import('../../views/admin/Integrations.vue'),
		meta: {layout: 'admin'}
	},
	{
		path: '/admin/integrations/add-platform',
		name: 'AdminIntegrationsAddPlatform',
		component: () => import('../../views/admin/IntegrationsPlatform.vue'),
		meta: {layout: 'admin'}
	},
	{
		path: '/admin/integrations/:id',
		name: 'AdminIntegrationsEditPlatform',
		component: () => import('../../views/admin/IntegrationsPlatform.vue'),
		meta: {layout: 'admin'}
	}
];