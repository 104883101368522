<template>
	<ol
		:class="classes"
		@dragstart.stop="dragStart"
		@dragend.stop="dragEnd"
		@dragover.stop="dragOver"
		@dragleave.stop="dragLeave"
		@drop.stop="drop"
	>
		<EditorCourseTreeNode v-for="node in value" :key="node.id" :node="node" :list="value" />
	</ol>
</template>

<script>
	import Backend from '../inc/backend';
	import Bus from '../inc/bus';
	import {copyBlock, fetchGroupBlocks, getGroup, hasAncestor} from '../inc/courseUtils';
	import {after, before, between, middle} from '@webbmaffian/rank';
	import Store from '../inc/store';
	import EditorCourseTreeNode from './EditorCourseTreeNode';
	import SettingsModalCourse from './SettingsModalCourse';

	/* eslint-disable require-jsdoc */
	export default {
		name: 'CourseTree',
		components: {
			EditorCourseTreeNode
		},
		props: {
			value: {
				type: Array,
				default: () => []
			}
		},

		data() {
			return {
				dragging: false
			};
		},

		computed: {
			editMode() {
				return Store.editMode;
			},
			draggingBlock() {
				return Store.blockDragging;
			},
			classes() {
				return {'course-tree': true, 'dragging': this.isDragging};
			},
			isDragging() {
				return !!this.dragging;
			}
		},

		watch: {
			dragging(isDragging, wasDragging) {
				(isDragging || wasDragging).component.$emit('dragging', !!isDragging);
			}
		},

		created() {
			Bus.on('openCourseSettings', courseId => {
				const course = getGroup(courseId);

				this.$modal.hideAll();
				this.$modal.show(SettingsModalCourse, {course}, {classes: 'settings-modal', height: 'auto'});
			});

			Bus.on('saveCourseSettings', group => {
				if(group.name.length < 1) {
					Bus.emit('error', 'Course name cannot be empty.');
				}
				else if(group.name.length > 256) {
					Bus.emit('error', 'Course name is too long.');
				}
				else {
					Backend.put(Store.course.id === group.id ? `courses/${Store.course.id}` : `courses/${Store.course.id}/groups/${group.id}`, {
						name: group.name,
						meta: group.meta
					}).catch(err => Bus.emit('error', err.message));
				}
			});
		},

		methods: {
			dragStart(e) {
				const target = getDomNode(e);

				if(!target) {
					return;
				}

				e.dataTransfer.effectAllowed = 'move';
				this.dragging = target;
			},
			dragEnd(e) {
				const target = getDomNode(e);

				if(!target) {
					return;
				}

				this.dragging = false;
			},
			dragOver(e) {
				const target = getDomNode(e);

				if(!target) {
					return;
				}

				e.preventDefault();
				e.dataTransfer.dropEffect = 'move';

				if(this.isDragging) {

					// Abort if the cursor hasn't moved - we don't wanna spam events.
					if(lastKnownCursorPos.y === e.y) {
						return;
					}

					lastKnownCursorPos.x = e.x;
					lastKnownCursorPos.y = e.y;

					if(!canBeMoved(this.dragging.node, target.node)) {
						return;
					}

					target.component.$emit('mouseover', getDropPosition(e.offsetY));
				}
				else {
					target.component.$emit('mouseover', 'inside');
				}
			},
			dragLeave(e) {
				const target = getDomNode(e);

				if(!target) {
					return;
				}

				target.component.$emit('mouseout');
			},
			drop(e) {
				e.stopPropagation();

				const target = getDomNode(e);

				if(!target) {
					return false;
				}

				target.component.$emit('mouseout');

				if(this.isDragging) {
					const {node, list} = this.dragging;

					if(!canBeMoved(node, target.node)) {
						return false;
					}

					const pos = getDropPosition(e.offsetY);
					const currentIndex = list.findIndex(n => n === node);

					if(currentIndex === -1) {
						throw new Error('Node not found');
					}

					let newList = target.list;
					let newIndex = -1;
					let parentId = null;

					if(pos === 'inside') {
						if(!target.node.children) {
							target.node.children = [];
						}

						newList = target.node.children;
						newIndex = target.node.children.length;
						parentId = target.node.id;
					}
					else {
						newIndex = newList.findIndex(n => n === target.node);
						parentId = target.node.parentId;

						if(newIndex === -1) {
							throw new Error('Node not found');
						}

						if(pos === 'after') {
							newIndex++;
						}
					}

					if(newList === list) {
						if(newIndex === currentIndex) {
							return false;
						}

						if(newIndex > currentIndex) {
							newIndex--;
						}
					}

					list.splice(currentIndex, 1);
					newList.splice(newIndex, 0, node);

					movedGroup(node.id, parentId, newIndex, newList).then(sortOrder => {
						node.sortOrder = sortOrder;
					});

					this.$emit('change');

					this.dragging = false;
				}
				else if(this.draggingBlock && (Store.courseGroup.id !== target.node.id)) {
					this.draggingBlock.blocks.splice(this.draggingBlock.index, 1);
					const {block} = this.draggingBlock;

					copyBlock(block, Store.course.id, target.node.id)
						.then(() => Backend.delete(`courses/${Store.course.id}/groups/${Store.courseGroup.id}/blocks/${this.block.id}`))
						.then(() => fetchGroupBlocks());
				}

				return false;
			}
		}
	};

	const rowHeight = 32;
	const domNodes = new WeakMap();
	const lastKnownCursorPos = {
		x: 0,
		y: 0
	};

	export function setDomNode(element, node, list, component) {
		domNodes.set(element, {node, list, component});
	}

	export function getDomNode(event) {
		const path = event.composedPath ? event.composedPath() : event.path;

		for(const element of path) {
			if(element.tagName === 'LI') {
				return domNodes.get(element);
			}
		}

		return false;
	}

	function getDropPosition(offsetY) {
		const part = offsetY / rowHeight;

		if(part < 0.25) {
			return 'before';
		}

		if(part > 0.75) {
			return 'after';
		}

		return 'inside';
	}

	function canBeMoved(movedNode, destinationNode) {
		if(movedNode === destinationNode) {
			return false;
		}

		if(hasAncestor(destinationNode.id, movedNode.id)) {
			return false;
		}

		return true;
	}

	/**
	 * @param {string} groupId The group ID
	 * @param {string} parentId The parent ID
	 * @param {number} index The groups index in list
	 * @param {array} list The list of groups
	 * @returns {Promise<string>} The new sort order
	 */
	function movedGroup(groupId, parentId, index, list) {
		if(!list.length) {
			return Promise.resolve(null);
		}

		let sortOrder = null;

		// The group is alone in the list
		if(list.length === 1) {
			sortOrder = middle();
		}

		// The group is first in the list
		else if(index === 0) {
			sortOrder = before(list[index + 1].sortOrder);
		}

		// The group is last in the list
		else if(index === list.length - 1) {
			sortOrder = after(list[index - 1].sortOrder);
		}

		// The group is between two items in the list
		else {
			sortOrder = between(list[index - 1].sortOrder, list[index + 1].sortOrder);
		}

		return Backend.put(`courses/${Store.course.id}/groups/${groupId}`, {
			parent: parentId,
			sortOrder
		}).then(() => sortOrder);
	}
</script>

<style lang="scss" scoped>
	ol {
		padding-left: 0;
	}

	.dragging::v-deep .router-link-exact-active {
		&::after {
			content: none;
		}
	}
</style>